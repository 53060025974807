export const getSelectedPayload = (actionArgsWhat, archetypePayloadSpec) => {
    if (!archetypePayloadSpec) {
        return null;
    }
    for (let count=0; count<archetypePayloadSpec.length; count++) {
        if (archetypePayloadSpec[count]._id == actionArgsWhat ||  archetypePayloadSpec[count].generatedId == actionArgsWhat) {
            return archetypePayloadSpec[count];
        }
    }
    return null;
}

