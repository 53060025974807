import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import gamesApi from '../../apis/gamesApi';
import GamePeriodForm from '../forms/GamePeriodForm';
import { groupBy } from 'lodash';
import { Alert, Modal, Dropdown, Button, Card } from 'react-bootstrap';

import { fetchActiveInstancesThinList, sendMessageToGame, applyExpirationAC } from '../../actions/gameInstancesActions';
import { fetchGame, editGameVersionPlayingPeriod, terminateAllGameInstances } from '../../actions/gamesActions';
import { fetchGameGameVersions } from '../../actions/gameVersionsActions';
import MainContent from '../layouts/MainContent';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PlayersMap from '../gmap/PlayersMap';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import UserInfoModal from '../users/UserInfoModal';

const SHOW_TYPES = {
  LIVE_ONLY: 'live-only',
  PUBLISHED_ONLY: 'published-only',
  BOTH: 'both',
};

const ActiveGames = ({ app, fetchActiveInstancesThinList, dispatch, match, history }) => {
  const { activeGameId } = match.params;

  const { t } = useTranslation();
  const instances = useSelector((state) => state.gameInstances.activeInstances);
  const game = useSelector((state) => state.games[activeGameId]);
  const gameVersions = useSelector((state) => state.gameVersions);
  const activeGameVersion = useSelector((state) => {
    let gv = null;
    if (state.gameVersions) {
      gv = Object.values(gameVersions).find((gv) => !gv.isLiveSpec && !gv.isArchived);
      if (!gv) {
        gv = Object.values(gameVersions).find((gv) => !gv.isArchived);
      }
    }
    return gv;
  });
  /* const activeGameVersion =
    gameVersions &&
    Object.values(gameVersions).find((gv) => !gv.isLiveSpec && !gv.isArchived); */
  const gameSpec = activeGameVersion && activeGameVersion.game && activeGameVersion.game.spec;
  const scenes = gameSpec && gameSpec.script && gameSpec.script.scenes;
  const [playerModal, setPlayerModal] = useState({ isOpen: false, player: {} });
  const [sendMessageModal, setSendMessageModal] = useState({
    isOpen: false,
    gameVersion: {},
  });
  const [applyExpirationModal, setApplyExpirationModal] = useState({
    isOpen: false,
    gameVersion: {},
  });
  const [terminateAllInstancesModal, setTerminateAllInstancesModal] = useState({
    isOpen: false,
    gameVersion: {},
  });
  const [editPeriodModal, setEditPeriodModal] = useState({ isOpen: false });
  const [messageValues, setMessageValues] = useState({ asynchronous: true });
  const [showType, setShowType] = useState(SHOW_TYPES.BOTH);
  const [playerStates, setPlayerStates] = useState([]);
  const [displayedInstances, setDisplayedInstances] = useState([]);

  const fetchPlayerStates = useCallback(
    (type) => {
      gamesApi
        .get(`/api/manage/gameInstances/${activeGameId}/playerStates${type ? `?showType=${type}` : ''}`)
        .then((response) => {
          setPlayerStates(response.data);
        })
        .catch((err) => {});
    },
    [activeGameId]
  );

  useEffect(() => {
    dispatch(fetchActiveInstancesThinList(activeGameId));
    dispatch(fetchGame(activeGameId));
    dispatch(fetchGameGameVersions(activeGameId));
    fetchPlayerStates(showType);
  }, [activeGameId, app, dispatch, fetchActiveInstancesThinList, fetchPlayerStates, showType]);

  useEffect(() => {
    if (!instances) {
      return;
    }
    if (showType === SHOW_TYPES.BOTH) {
      setDisplayedInstances(instances);
    } else if (showType === SHOW_TYPES.LIVE_ONLY) {
      setDisplayedInstances(instances.filter((i) => i.gameVersion.isLiveSpec === true));
    } else {
      setDisplayedInstances(instances.filter((i) => i.gameVersion.isLiveSpec === false));
    }
  }, [instances, activeGameVersion, showType]);

  const onChangePeriodSubmit = (formValues) => {
    dispatch(editGameVersionPlayingPeriod(activeGameVersion.id, formValues))
      .then(async () => {
        setEditPeriodModal({ isOpen: false });
        await dispatch(fetchActiveInstancesThinList(activeGameId));
        await dispatch(fetchGameGameVersions(activeGameId));
      })
      .catch((e) => {
        /* do nothing. An error toastr should have been displayed */
      });
  };

  const onTerminateAllConfirm = () => {
    dispatch(terminateAllGameInstances(game._id))
      .then(() => {
        dispatch(fetchActiveInstancesThinList(activeGameId));
        setTerminateAllInstancesModal({ isOpen: false });
      })
      .catch((e) => {
        /* do nothing. An error toastr should have been displayed */
      });
  };

  const showPlayerInfo = (e, player) => {
    e.preventDefault();
    setPlayerModal({ isOpen: true, player });
  };

  const showSendMessage = (e, gameVersion) => {
    e.preventDefault();
    setSendMessageModal({ isOpen: true, gameVersion });
  };

  const showTerminateModal = (e, game) => {
    e.preventDefault();
    setApplyExpirationModal({ isOpen: true, game });
  };

  const showTerminateAllModal = (e, gameId) => {
    e.preventDefault();
    setTerminateAllInstancesModal({ isOpen: true, gameId });
  };

  const showEditPeriodModal = (e, gameVersion) => {
    e.preventDefault();
    setEditPeriodModal({ isOpen: true });
  };

  const sendMessage = () => {
    dispatch(
      sendMessageToGame(activeGameVersion.gameId, {
        ...messageValues,
        gameVersionId: activeGameVersion.id,
      })
    );
    setSendMessageModal({ isOpen: false, gameVersion: {} });
    setMessageValues({});
  };

  const terminateExpiredInstances = () => {
    dispatch(applyExpirationAC(applyExpirationModal.game.id));
    setApplyExpirationModal({ isOpen: false, game: {} });
  };

  const showPlayersInfo = (players) => {
    const statusGroups = groupBy(players, 'status');
    return players.length > 0 ? (
      <>
        {players.length}[
        {Object.keys(statusGroups).map((key) => (
          <span key={key} className='text-secondary'>
            {key}: {statusGroups[key].length}{' '}
          </span>
        ))}
        ]
      </>
    ) : (
      <>{players.length}</>
    );
  };

  const statusSelectOptions = {
    active: 'active',
    completed: 'completed',
    abandoned: 'abandoned',
    expired: 'expired',
    terminated: 'terminated',
  };

  const playerStatusOptions = {
    playing: 'playing',
    paused: 'paused',
    'left game': 'left game',
    movedTo: 'movedTo',
  };

  const filterByStatus = (filterVal, data) => {
    if (filterVal) {
      return data.filter((data) => data.players.some((p) => p.status.includes(filterVal)));
    }
    return data;
  };

  const columns = [
    {
      dataField: 'state.worldState.status',
      text: 'World Status',
      sort: true,
      // filter: textFilter(),
      filter: selectFilter({
        options: statusSelectOptions,
      }),
    },
    {
      dataField: 'playerstatuses',
      text: 'Player Statuses',
      filter: selectFilter({
        options: playerStatusOptions,
        onFilter: filterByStatus,
      }),
      formatter: (c, r) => <div>{showPlayersInfo(r.players)}</div>,
    },
    {
      dataField: 'players',
      text: 'Players',
      sort: false,
      formatter: (c, r) => (
        <div>
          {r.players.map((p, i) => [
            <a href='#' onClick={(e) => showPlayerInfo(e, p)} key={p.id}>
              {p.name}
            </a>,
            i === r.players.length - 1 ? '' : ', ',
          ])}
        </div>
      ),
    },
    {
      dataField: 'gameVersion.game.playingPeriod',
      text: 'Period',
      formatter: (c, r) => {
        const now = new moment().utc();
        const { playingPeriod } = r.gameVersion.game;

        let isExpired = false;

        if (playingPeriod) {
          const { start, end } = playingPeriod;
          if (start && moment(start).utc().isAfter(now)) {
            isExpired = true;
          }

          if (end && moment(end).utc().isBefore(now)) {
            isExpired = true;
          }
        }

        if (!playingPeriod) {
          return '-';
        }

        return (
          <div className={`${isExpired ? 'text-danger fw-bold' : ''}`}>
            {playingPeriod.start ? moment(playingPeriod.start).format('DD MMM YYYY, HH:mm') : ''}
            {playingPeriod.start && playingPeriod.end && ' - '}
            {playingPeriod.end ? moment(playingPeriod.end).format('DD MMM YYYY, HH:mm') : ''}
          </div>
        );
      },
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (c, r) => <Button onClick={() => history.push(`/active-games/${activeGameId}/instance/${r.id}`)}>Manage</Button>,
    },
  ];

  if (!game || !activeGameVersion) {
    return <Alert variant='danger'>Game not found. Sorry.</Alert>;
  }
  const _allPlayers = [];
  displayedInstances &&
    displayedInstances.forEach((inst) => {
      inst.players.forEach((p) => {
        _allPlayers.push(p);
      });
    });

  const playingPeriod = activeGameVersion && activeGameVersion.game && activeGameVersion.game.playingPeriod;
  return (
    <>
      <MainContent hasSideBar={false}>
        <Card className='shadow-sm'>
          <Card.Header className='d-flex font-weight-bold align-items-center bg-rlck-primary txt-white'>
            Game: {game.title}
            <div className='ml-auto'>
              <Dropdown>
                <Dropdown.Toggle variant='info' id='dropdown-basic' className='txt-white'>
                  Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => showSendMessage(e, game)}>Send message to players</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => showTerminateModal(e, game)}>
                    <span className='text-danger'>Terminate expired instances</span>
                  </Dropdown.Item>
                  {/*<Dropdown.Item onClick={(e) => showTerminateAllModal(e, game)}>*/}
                  {/*  <span className="text-danger">Terminate all instances</span>*/}
                  {/*</Dropdown.Item>*/}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>

          <Card.Body>
            <div className='row mb-2'>
              <div className='col-sm'>
                <div className='mt-2'>
                  <span className='text-secondary'>Created at:</span> <time>{moment(game.createdAt).format('DD MMM YYYY, HH:mm')}</time>
                </div>

                <div className='mt-2'>
                  <span className='text-secondary'>Instances:</span> {displayedInstances.length}
                </div>

                <div className='mt-2'>
                  <span className='text-secondary'>Is multiplayer:</span> {game.multiplayer ? 'Yes' : 'No'}
                </div>

                <div className='mt-2'>
                  <span className='text-secondary'>Total Players:</span> {showPlayersInfo(_allPlayers)}
                </div>

                {playingPeriod && (
                  <div className='mt-2'>
                    <span className='text-secondary'>Period: </span>
                    {playingPeriod.start ? moment(playingPeriod.start).format('DD MMM YYYY, HH:mm') : ''}
                    {playingPeriod.start && playingPeriod.end && ' - '}
                    {playingPeriod.end ? moment(playingPeriod.end).format('DD MMM YYYY, HH:mm') : ''}
                    <button className='btn btn-sm btn-secondary ml-3' onClick={showEditPeriodModal}>
                      Change
                    </button>
                  </div>
                )}
              </div>
              <div className='col-sm text-lg-right'>
                <div>
                  <label className='mr-2 font-weight-bold' htmlFor='show-type'>
                    Include instances:
                  </label>
                  <select
                    id='show-type'
                    value={showType}
                    onChange={(e) => {
                      setShowType(e.target.value);
                      fetchPlayerStates(e.target.value);
                    }}>
                    {Object.values(SHOW_TYPES).map((st) => (
                      <option value={st}>{t(`SHOW_TYPE_${st}`)}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {displayedInstances.length > 0 && (
              <div className='row p-0'>
                <div className='col-12 text-lg-right p-0'>
                  <PlayersMap
                    onRefresh={() => {
                      fetchPlayerStates(showType);
                    }}
                    center={{ lat: game?.lat, lng: game.lng }}
                    usersState={playerStates}
                    onPlayerMarkerClick={(e, player) => {
                      const playerState = playerStates.find((pl) => pl.id === player.id) ?? {};
                      const { id, name } = player;
                      const { email, status } = playerState;
                      showPlayerInfo(e, {
                        id,
                        name,
                        email,
                        status,
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </Card.Body>

          <Card.Footer className='bg-white p-3'>
            {displayedInstances.length === 0 && <Alert variant='danger'>No instances found</Alert>}
            {displayedInstances.length > 0 && (
              <BootstrapTable
                keyField='id'
                data={displayedInstances}
                columns={columns}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10,
                    },
                    {
                      text: '20',
                      value: 20,
                    },
                    {
                      text: '50',
                      value: 50,
                    },
                    {
                      text: 'All',
                      value: instances.length,
                    },
                  ],
                })}
              />
            )}
          </Card.Footer>
        </Card>

        <UserInfoModal isOpen={playerModal.isOpen} player={playerModal.player} onHide={() => setPlayerModal({ isOpen: false, player: {} })} gameId={game.id} isThinData />

        {sendMessageModal.isOpen && (
          <Modal show={sendMessageModal.isOpen} onHide={() => setSendMessageModal({ isOpen: false, game: {} })}>
            <Modal.Header closeButton>
              <Modal.Title>Send Message</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Alert variant='info' className='mb-4'>
                This is will send a message to each and every one playing <strong>{game.title}</strong> or to all players located in a specific scene.
              </Alert>

              <div className='mb-2'>
                <form>
                  <div className='form-group'>
                    <label htmlFor='scene'>Scene</label>
                    <select
                      className='form-control'
                      name='sceneId'
                      onChange={(e) =>
                        setMessageValues({
                          ...messageValues,
                          sceneId: e.target.value,
                        })
                      }>
                      <option value=''>All Scenes</option>
                      {scenes.map((scene) => (
                        <option key={scene.id} value={scene.id}>
                          {scene.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {(!messageValues.sceneId || messageValues.sceneId === '') && (
                    <div className='form-group'>
                      <input
                        type='checkbox'
                        name='async-message'
                        checked={messageValues.asynchronous}
                        onChange={() =>
                          setMessageValues({
                            ...messageValues,
                            asynchronous: !messageValues.asynchronous,
                          })
                        }
                      />
                      <label style={{ paddingLeft: 5 }}>Asynchronous Message</label>
                    </div>
                  )}
                  <div className='form-group'>
                    <label htmlFor='message'>Message</label>

                    <textarea
                      onChange={(e) =>
                        setMessageValues({
                          ...messageValues,
                          message: e.target.value,
                        })
                      }
                      style={{ height: '200px' }}
                      placeholder='Type your massage here...'
                      name='message'
                      className='form-control'></textarea>

                    <div className='text-right mt-3'>
                      <button type='button' onClick={sendMessage} className='btn btn-primary'>
                        Send message
                      </button>
                    </div>
                    <div className='mt-3'>
                      {!messageValues.asynchronous && (!messageValues.sceneId || messageValues.sceneId === '') && (
                        <Alert variant='warning' className='mb-4'>
                          Synchronous messages are <strong>only delivered to the players</strong> who are currently online and they have the Rollick App open.
                        </Alert>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {applyExpirationModal.isOpen && (
          <Modal show={applyExpirationModal.isOpen} onHide={() => setApplyExpirationModal({ isOpen: false, game: {} })}>
            <Modal.Header closeButton>
              <Modal.Title>Apply expiration</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              All active instances of <strong>{game.title}</strong> will get expired..
              <br />
              Please confirm action.
              <div className='text-right mt-5'>
                <button
                  type='button'
                  onClick={() => {
                    const { game } = applyExpirationModal;
                    dispatch(applyExpirationAC(game.id)).then(() => {
                      setApplyExpirationModal({ isOpen: false, game: {} });
                      dispatch(fetchActiveInstancesThinList(activeGameId));
                      dispatch(fetchGame(activeGameId));
                    });
                  }}
                  className='btn btn-primary'>
                  Confirm
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {terminateAllInstancesModal.isOpen && (
          <Modal show={terminateAllInstancesModal.isOpen} onHide={() => setTerminateAllInstancesModal({ isOpen: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Terminate expired instances</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              All instances of <strong>{game.title}</strong> will get terminated.
              <br />
              Please confirm action.
              <div className='text-right mt-5'>
                <button type='button' onClick={onTerminateAllConfirm} className='btn btn-primary'>
                  Confirm
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {editPeriodModal.isOpen && (
          <Modal show={editPeriodModal.isOpen} onHide={() => setEditPeriodModal({ isOpen: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Change Game Period</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <GamePeriodForm
                  enableReinitialize={true}
                  handleCancel={() => setEditPeriodModal({ isOpen: false })}
                  initialValues={{
                    playingPeriod,
                  }}
                  onSubmit={onChangePeriodSubmit}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
      </MainContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};
//export default connect(mapStateToProps, {fetchMyGames, deleteGame})(GameList);
export default withRouter(
  connect(mapStateToProps, (dispatch) => ({
    dispatch,
    fetchActiveInstancesThinList,
  }))(ActiveGames)
);
