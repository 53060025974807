import React, {useState} from 'react';

import {change, Field, FieldArray, formValueSelector,reduxForm} from 'redux-form';
import {connect, useSelector} from "react-redux";
import {RULE_FORM_NAME as FORM_NAME} from "./formTypes";
import renderInput from "./renderers/renderInput";
import renderCheckbox from "./renderers/renderCheckbox";
import '../../i18n';

import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import ModalEdit from "../utils/ModalEdit";
import InteractionEdit from "../interactions/InteractionEdit";
import ConditionForm2 from './ConditionForm2';
import SideEffectForm2 from './SideEffectForm2';
import {useTranslation} from "react-i18next";
import FormButtons from "./FormButtons";
import {asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate} from "../utils/validationUtils";

import Condition from "./Condition";

const selector = formValueSelector(FORM_NAME);



const RuleForm = ({error, dispatch, handleSubmit, onSubmit, handleCancel, scene, game, handleDelete, initialValues}) => {
    const { t, i18n } = useTranslation();

    let allItems = useSelector((state)=>state.items);
    let allArchetypes = useSelector((state)=>state.archetypes);
    let allAttributes = useSelector((state)=>state.attributes);
    let allInteractions = useSelector((state)=>state.interactions);
    let allMilestones = useSelector((state)=>state.milestones);
    let allMissions = useSelector((state)=>state.missions);
    let allLocations = useSelector((state)=>state.locations);

    let gameInteractions = Object.values(allInteractions).filter(function (el) {return el.gameId === game.id;});
//    let gameItems = Object.values(allItems).filter(function (el) {return el.gameId === game.id;});
    let gameItems = Object.values(allItems).filter(function (el) {return el.gameId === game.id && !el.isCurrency;});
    let gameArchetypes = Object.values(allArchetypes).filter(function (el) {return el.gameId === game.id;});
    let gameAttributes = Object.values(allAttributes).filter(function (el) {return el.gameId === game.id;});
    let gameMilestones = Object.values(allMilestones).filter(function (el) {return el.gameId === game.id;});
    let gameMissions = Object.values(allMissions).filter(function (el) {return el.gameId === game.id;});
    let sceneLocations = Object.values(allLocations).filter(function (el) {return el.sceneId === scene.id;});

    const [openEditInteractionModal, setOpenEditInteractionModal] = useState(false);
    const [newInteractionType, setNewInteractionType] = useState(null);
    const [newInteractionFieldToUpdate, setNewInteractionFieldToUpdate] = useState(null);
    const [selectedInteractionToEdit, setSelectedInteractionToEdit] = useState({});

    const handleInteractionSaved = (savedInteraction) => {
        closeEditInteractionModal();
        // we should select the saved interaction in the dropdown list
        // savedInteraction.id;

        //dispatch(change(FORM_NAME, "activateInteractionId", savedInteraction.id));
        dispatch(change(FORM_NAME, newInteractionFieldToUpdate, savedInteraction.id));
    }
    const closeEditInteractionModal = () => {
        setOpenEditInteractionModal(false);
    };
    const cancelEditInteractionModal = () => {
        setOpenEditInteractionModal(false);
    };


    const newInteractionHandler = (type, fieldToUpdateAfterSave) => {
        setSelectedInteractionToEdit(null);
        setNewInteractionType(type);
        setNewInteractionFieldToUpdate(fieldToUpdateAfterSave);
        setOpenEditInteractionModal(true);
        //history.push(`/games/${game.id}/interactions/create/0/${scene.id}`);
    }
    const editInteractionHandler = (id) => {
        const interaction = gameInteractions.find(element => element.id === id);
        setSelectedInteractionToEdit(interaction);
        setOpenEditInteractionModal(true);
        //history.push(`/games/${game.id}/interactions/edit/${id}`);
    }

    const myOnSubmit = (formValues) => {
        //console.log('SKATA2', FORM_NAME);

        onSubmit(formValues);
    };
    // const getTriggerOptions = (interactions, milestones, includeCodes) => {
    //     let triggerOptions = [];
    //     triggerOptions.push({id:ENTERSCENE,title:'Enter Scene'});
    //     triggerOptions.push({id:LEAVESCENE,title:'Leave Scene'});
    //
    //     return triggerOptions;
    // };

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
                        <Field name="sceneId" component="input" type="hidden" />
                        <Field name="gameId" component="input" type="hidden" />
                        <Field name="name" label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />
                        <Field asCol={false} name="notes" type="textarea" label="Notes" tooltip={t('NOTES_FIELD_TOOLTIP')} component={renderInput} />

                        <fieldset className="border p-2 mb-3">
                            <legend className="w-auto legend-medium">When</legend>
                            <Condition condition={'trigger'} fields={null} index={null} operator={null} scene={scene} game={game} formName={FORM_NAME} dispatch={dispatch} isTriggerCondition={true}/>
                            <Field component={renderCheckbox} name="fireOnce" type="checkbox" label="Fire once" />
                        </fieldset>


                                <div className={'sideeffectssection'}>
                                    <fieldset className="border p-2 mb-3">
                                        <legend className="w-auto legend-medium">Do the following</legend>
                                        <SideEffectForm2 fieldName="sideeffects"
                                                     game={game}
                                                     newInteractionHandler={newInteractionHandler}
                                                     formName={FORM_NAME} forArcheType={false} ></SideEffectForm2>
                                    </fieldset>
                                </div>


                        <fieldset className="border p-2 mb-3">
                            <legend className="w-auto legend-medium">But only if</legend>                                <ConditionForm2 dispatch={dispatch} scene={scene} game={game}
                                                baseFieldName={'triggerPreconditions'}
                                                formName={FORM_NAME}
                                ></ConditionForm2>
                        </fieldset>

                        <div className={'sideeffectssection'}>
                            <fieldset className="border p-2 mb-3">
                                <legend className="w-auto legend-medium">Else do the following</legend>
                            <SideEffectForm2 fieldName="sideeffectselse"
                                            game={game}
                                            newInteractionHandler={newInteractionHandler}
                                            formName={FORM_NAME} forArcheType={false} isElse={true}></SideEffectForm2>
                            </fieldset>
                        </div>

                        {
                            // <h3>Only if preconditions</h3>
                            // <ConditionForm dispatch={dispatch} scene={scene} game={game}
                            // gameInteractions={gameInteractions}
                            // gameMilestones={gameMilestones}
                            // sceneLocations={sceneLocations}
                            // ></ConditionForm>
                        }

                        <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id?handleDelete:null} />


                    </Form>
                </Col>
            </Row>

            <ModalEdit openEditModal={openEditInteractionModal} closeEditModal={closeEditInteractionModal}
                       heading={"Edit or create scene element"}
                       portal="interaction">
                <InteractionEdit game={game} interaction={selectedInteractionToEdit}
                                 newInteractionType={newInteractionType}
                                 onSave={handleInteractionSaved}
                                 onCancel={cancelEditInteractionModal}
                />
            </ModalEdit>
        </Container>
    );

}

const validate = (formValues) => {
    const errors = {};
    g4mFormValidate(errors, formValues, ['triggerPreconditions','sideeffects:atleastone','sideeffectselse','trigger']); // name is validated asynchronously
    g4mFormTotalFormValidate(errors);


    //console.log("Validation errors of rule form", errors);
    return errors;
};


// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
// })(RuleForm);

const mapStateToProps = (state, ownProps) => {
    return {
        title:selector(state, 'title'),
    };
};
export default connect(
    mapStateToProps
)(reduxForm(
    {
        form: FORM_NAME,
        validate:validate,
        asyncValidate:asyncValidateForm,
        asyncBlurFields: [ 'name' ]
    }
)(RuleForm));