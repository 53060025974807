import { change, Field, formValueSelector } from 'redux-form';
import renderInput from './renderers/renderInput';
import renderSelect from './renderers/renderSelect';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import {
  INSTANCE_PROP_IMAGE,
  INSTANCE_PROP_NUMBER,
  INSTANCE_PROP_TEXTMULTI,
  INSTANCE_PROP_TEXTSINGLE,
  INSTANCE_PROP_SOUND,
  INSTANCE_PROP_VIDEO,
  INSTANCE_PROP_EMAIL,
} from '../archetypes/archetypeConstants';
import { useTranslation } from 'react-i18next';
import FieldArrayButtons from './FieldArrayButtons';
import { shortLabelForConversationStatement } from '../../utils/utils';
import { getSelectedPayload } from '../utils/variousUtils';
import {
  itemPlayerActions,
  itemWorldActions,
  // attributePlayerActions,
  // attributeWorldActions,
  // attributeArchetypeActions,
  // communicationActions,
  // gameActions,
  archetypeActions,
  actionGroupActions,
  actionGroupActionsWithArchetypes,
  actionGroupActionsWithConversations,
  conversationActions,
} from '../utils/sideeffectConstants';
//import {CONDITION_TYPE_SELECT_OPTIONS, CONDITION_TYPE_VERBS} from "./conditionConstants";
import { INTER_ANSWER_QUESTION, INTER_CONFIRM, INTER_CONVERSATION, INTER_INSPECT_INFO, INTER_QUIZ } from '../interactions/interactionTypes';
import { ARCHETYPE_FORM_NAME, OFFER_FORM_NAME } from './formTypes';

let SideEffect2 = ({
  dispatch,
  sideEffect,
  index,
  fields,
  newInteractionHandler,
  actionName,
  items,
  scenes,
  gameInteractions,
  attributes,
  archetypes,
  characters,
  missions,
  forArcheType,
  isElse,
  archetypePayloadSpec,
  actionArgsWhat,
  forConversation,
  currentStatementIndex,
  conversationStatements,
  numberPropHow,
  archetypePickable,
  archetypeDestroyable,
  isMultiplayer,
  formName,
}) => {
  const { t, i18n } = useTranslation();
  const getActorOptions = (characters) => {
    let elementOptions = [];
    for (const npc of characters) {
      if (!npc.isPlayer || (npc.isPlayer && [ARCHETYPE_FORM_NAME, OFFER_FORM_NAME].includes(formName))) {
        elementOptions.push({ id: npc.id, title: npc.name });
      }
    }
    return elementOptions;
  };
  const renderActionOptions = (group, groups, isMultiplayer) => {
    const options = groups[group];
    return options.map((option) => {
      let translationKey = 'action_' + option;
      if (isMultiplayer && option === 'endGame') {
        translationKey = translationKey + '_MULTI';
      }
      if (!isMultiplayer && option === 'completeGame') {
        return <React.Fragment key={option}></React.Fragment>;
      }
      return (
        <option key={option} value={option}>
          {t(translationKey)}
        </option>
      );
    });
  };
  let otherDetailsComponent = <></>;

  const handleActorChange = (event, newValue, previousValue, name) => {};
  const handleWhatChange = (event, newValue, previousValue, name) => {};

  const handleActionChange = (event, newValue, previousValue, name) => {
    dispatch(change(formName, sideEffect + 'actionArgs.what', null)); // NOT empty string!!!
    dispatch(change(formName, sideEffect + 'actionArgs.value', null)); // NOT empty string!!!
    dispatch(change(formName, sideEffect + 'actionArgs.message', null)); // NOT empty string!!!
  };
  // const handleElementTypeChange = (event, newValue, previousValue, name) => {
  //     // when changed, we have to reset everything
  //     dispatch(change(formName, sideEffect+'elementId', null)); // NOT empty string!!!
  //     dispatch(change(formName, sideEffect+'actionName', null)); // NOT empty string!!!
  //     dispatch(change(formName, sideEffect+'actorId', null)); // NOT empty string!!!
  //     dispatch(change(formName, sideEffect+'notify', false)); // NOT empty string!!!
  //     dispatch(change(formName, sideEffect+'value', '')); // NOT empty string!!!
  // }
  const actorOptions = getActorOptions(characters);
  const actorComponent = (
    <Field
      noFormGroup={true}
      asCol={false}
      hideLabel={true}
      no_separate_error={true}
      onChange={handleActorChange}
      name={`${sideEffect}.actorId`}
      normalize={(value, previousValue, allValues) => (value ? value : null)}
      component={renderSelect}
      label='Actor'>
      <option key={'empty'} value={'empty'}>
        - Select character -
      </option>
      <option key={'system'} value={''}>
        Rollick
      </option>
      {actorOptions.map((option) => (
        <option key={option.id} value={option.id}>
          {option.title}
        </option>
      ))}
    </Field>
  );

  let activeActionGroupActions = actionGroupActions;
  if (forArcheType) {
    activeActionGroupActions = actionGroupActionsWithArchetypes;
    // special case: remove from actionGroupActionsWithArchetypes.currentArchetypeActions array option pick/destroy object
    // if current archetype is not pickable/destroyable
    // I need to make a complete copy of actionGroupActionsWithArchetypes in order to remove the item, so that the original array
    // is not changed
    // I also remove pickable/destroyable if this is the ELSE part of the actions. So picking and destroying works only in the main actions
    if (!archetypePickable || !archetypeDestroyable || isElse) {
      const tmpArrayOfArrays = JSON.parse(JSON.stringify(activeActionGroupActions));
      if (!archetypePickable || isElse) {
        tmpArrayOfArrays.currentArchetypeActions = tmpArrayOfArrays.currentArchetypeActions.filter(function (e) {
          return e !== 'pickObject' || e !== 'pickObjectClone';
        });
      }
      if (!archetypeDestroyable || isElse) {
        tmpArrayOfArrays.currentArchetypeActions = tmpArrayOfArrays.currentArchetypeActions.filter(function (e) {
          return e !== 'destroyObject';
        });
      }
      activeActionGroupActions = tmpArrayOfArrays;
      // TODO: if e.g. pickObject was selected as action, and I uncheck the pickable, the select box shows "select action" but the form value is still pickObject
      // TODO: I would need to check all actions and call a redux form change() for those fields, or make the check in validation
    }
  }
  if (forConversation) {
    activeActionGroupActions = actionGroupActionsWithConversations;
  }
  const actionComponent = (
    <Field onChange={handleActionChange} name={`${sideEffect}.actionName`} noFormGroup={true} asCol={false} component={renderSelect} hideLabel={true} label='Action' no_separate_error={true}>
      <option value={''}>- Select action -</option>
      {Object.keys(activeActionGroupActions).map((group, index) => {
        return (
          <optgroup key={group} label={t('actiongroup_' + group)}>
            {renderActionOptions(group, activeActionGroupActions, isMultiplayer)}
          </optgroup>
        );
      })}
    </Field>
  );

  const getInteractionWhatOptions = (gameInteractions, type) => {
    let whatOptions = [];
    for (const what of gameInteractions) {
      if (what.type === type) whatOptions.push({ id: what.id, title: what.name });
    }
    return whatOptions;
  };

  const getCreateNewComponent = (actionName, fieldNameToUpdate) => {
    if (['showInfo', 'askQuestion', 'askQuiz', 'askConfirmation'].includes(actionName)) {
      let actionToType = {
        showInfo: INTER_INSPECT_INFO,
        askQuestion: INTER_ANSWER_QUESTION,
        askQuiz: INTER_QUIZ,
        askConfirmation: INTER_CONFIRM,
      };
      return (
        <Button
          title='create new'
          size='sm'
          variant='secondary'
          type='button'
          onClick={() => {
            newInteractionHandler(actionToType[actionName], fieldNameToUpdate);
          }}>
          <i className='fas fa-plus'></i>
        </Button>
      );
    }
    return null;
  };

  const getWhatOptions = (actionName, items, archetypes, attributes, gameInteractions, missions, scenes) => {
    let whatOptions = [];
    switch (actionName) {
      case 'showInfo':
        whatOptions = getInteractionWhatOptions(gameInteractions, INTER_INSPECT_INFO);
        break;
      case 'askQuestion':
        whatOptions = getInteractionWhatOptions(gameInteractions, INTER_ANSWER_QUESTION);
        break;
      case 'askQuiz':
        whatOptions = getInteractionWhatOptions(gameInteractions, INTER_QUIZ);
        break;
      case 'askConfirmation':
        whatOptions = getInteractionWhatOptions(gameInteractions, INTER_CONFIRM);
        break;
      case 'startConversation':
      case 'resumeConversation':
        whatOptions = getInteractionWhatOptions(gameInteractions, INTER_CONVERSATION);
        break;
      case 'enableScene':
      case 'disableScene':
        for (const what of scenes) {
          whatOptions.push({ id: what.id, title: what.name });
        }
        break;
      case 'assignMission':
      case 'archiveMission':
        for (const what of missions) {
          whatOptions.push({ id: what.id, title: what.name });
        }
        break;
      case 'showNotification':
        // nothing to do here
        break;
      case 'removePlayerObjects':
        for (const what of archetypes) {
          whatOptions.push({ id: what.id, title: what.name });
        }
        break;
      case 'giveToPlayer':
      case 'takeFromPlayer':
      case 'setOnPlayer':
      case 'giveToWorld':
      case 'takeFromWorld':
      case 'setOnWorld':
        for (const what of items) {
          whatOptions.push({ id: what.id, title: what.name });
        }
        break;
      case 'attachToPlayer':
      case 'detatchFromPlayer':
      case 'attachToWorld':
      case 'detatchFromWorld':
      case 'returnToWorld':
      case 'removeFromWorld':
        for (const what of attributes) {
          whatOptions.push({ id: what.id, title: what.name });
        }
        break;
      case 'endGame':
      case 'completeGame':
        // no select box here
        break;
      default:
    }

    return whatOptions;
  };

  let whatOptions = getWhatOptions(actionName, items, archetypes, attributes, gameInteractions, missions, scenes);
  let whatComponent = null;
  let createNewComponent = null;
  if (actionName !== 'showNotification' && actionName !== 'endGame' && actionName !== 'completeGame' && !archetypeActions.includes(actionName) && !conversationActions.includes(actionName)) {
    whatComponent = (
      <Field
        noFormGroup={true}
        asCol={false}
        hideLabel={true}
        no_separate_error={true}
        onChange={handleWhatChange}
        name={`${sideEffect}.actionArgs.what`}
        normalize={(value, previousValue, allValues) => (value ? value : null)}
        component={renderSelect}
        label='What'>
        <option key={'empty'} value={''}>
          - Select what -
        </option>
        {whatOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </Field>
    );
  }

  if (forConversation) {
    if (actionName === 'sayStatement') {
      whatComponent = (
        <Field noFormGroup={true} asCol={false} hideLabel={true} no_separate_error={true} name={`${sideEffect}.actionArgs.what`} component={renderSelect} label='Statement'>
          <option key={'empty'} value={''}>
            - Select one -
          </option>
          {conversationStatements
            .filter(function (el, index) {
              if (index !== currentStatementIndex) return true;
              else return false;
            })
            .map((option, index) => (
              <option key={option._id ? option._id : option.generatedId} value={option._id ? option._id : option.generatedId}>
                statement {index + 1 + (index >= currentStatementIndex ? 1 : 0)}: {shortLabelForConversationStatement(option)} currentStatementIndex:{currentStatementIndex}
              </option>
            ))}
        </Field>
      );
    }
  }

  if (whatComponent) {
    createNewComponent = getCreateNewComponent(actionName, `${sideEffect}.actionArgs.what`);
  }

  if (forArcheType && actionName === 'mutateObject') {
    whatComponent = (
      <Field noFormGroup={true} asCol={false} hideLabel={true} no_separate_error={true} name={`${sideEffect}.actionArgs.what`} component={renderSelect} label='What'>
        <option key={'empty'} value={''}>
          - Select what -
        </option>
        <option key={'perception.visible'} value={'perception.visible'}>
          Visibility
        </option>
        <option key={'perception.trackable'} value={'perception.trackable'}>
          Trackability
        </option>
        {archetypePayloadSpec &&
          archetypePayloadSpec
            .filter(function (el) {
              return [INSTANCE_PROP_TEXTSINGLE, INSTANCE_PROP_NUMBER, INSTANCE_PROP_EMAIL, INSTANCE_PROP_TEXTMULTI].includes(el.type) ? true : false;
            })
            .map((option, index) => (
              <option key={option._id ? option._id : option.generatedId} value={option._id ? option._id : option.generatedId}>
                {option.label}
              </option>
            ))}
      </Field>
    );

    let mutateValueControls = null;
    let numberPropHowControl = null;

    if (actionArgsWhat) {
      switch (actionArgsWhat) {
        case 'perception.visible':
        case 'perception.trackable':
          mutateValueControls = (
            <Field noFormGroup={true} asCol={false} hideLabel={true} no_separate_error={true} name={`${sideEffect}.actionArgs.value`} component={renderSelect} label='New value'>
              <option key={'empty'} value={''}>
                -- Select one --
              </option>
              <option key={'1'} value={'1'}>
                True
              </option>
              <option key={'0'} value={'0'}>
                False
              </option>
            </Field>
          );
          break;
        default:
          // id or generated id of payload. Depends of type of payload
          // archetypePayloadSpec contains all payloads. Find the one given by actionArgsWhat and prceed according to it's type
          const payload = getSelectedPayload(actionArgsWhat, archetypePayloadSpec);
          if (payload) {
            switch (payload.type) {
              case INSTANCE_PROP_NUMBER:
                let label = 'New number';
                numberPropHowControl = (
                  <Field noFormGroup={true} asCol={false} hideLabel={true} no_separate_error={true} name={`${sideEffect}.actionArgs.numberPropHow`} component={renderSelect} label='How'>
                    <option key={'empty'} value={''}>
                      -- Select one --
                    </option>
                    <option key={'+'} value={'+'}>
                      increase by
                    </option>
                    <option key={'-'} value={'-'}>
                      decrease by
                    </option>
                    <option key={'set'} value={'set'}>
                      set to
                    </option>
                  </Field>
                );

                if (numberPropHow === '-' || numberPropHow === '+') {
                  label = 'Value';
                }
                mutateValueControls = (
                  <>
                    <Field
                      noFormGroup={true}
                      asCol={false}
                      hideLabel={true}
                      name={`${sideEffect}.actionArgs.value`}
                      label={`${label}`}
                      type='number'
                      step='1'
                      component={renderInput}
                      pattern='[0-9]*'
                      inputMode='numeric'
                      normalize={(val) => (val || '').replace(/[^\d]/g, '')}
                    />
                  </>
                );
                break;
              case INSTANCE_PROP_TEXTSINGLE:
                mutateValueControls = <Field noFormGroup={true} asCol={false} hideLabel={true} name={`${sideEffect}.actionArgs.value`} label={`New text`} type='text' component={renderInput} />;
                break;
              case INSTANCE_PROP_EMAIL:
                mutateValueControls = <Field noFormGroup={true} asCol={false} hideLabel={true} name={`${sideEffect}.actionArgs.value`} label={`New email`} type='email' component={renderInput} />;
                break;
              case INSTANCE_PROP_TEXTMULTI:
                mutateValueControls = <Field noFormGroup={true} asCol={false} hideLabel={true} name={`${sideEffect}.actionArgs.value`} type='textarea' label='New text2' component={renderInput} />;

                break;
              case INSTANCE_PROP_IMAGE:
              case INSTANCE_PROP_SOUND:
              case INSTANCE_PROP_VIDEO:
                // not supported
                break;
              default:
            }
          }
      }
    }

    if (numberPropHowControl || mutateValueControls) {
      otherDetailsComponent = (
        <>
          {numberPropHowControl && numberPropHowControl}
          {mutateValueControls && mutateValueControls}
        </>
      );
    }
  }

  if (itemPlayerActions.includes(actionName) || itemWorldActions.includes(actionName)) {
    otherDetailsComponent = (
      <Field
        noFormGroup={true}
        asCol={false}
        hideLabel={true}
        no_separate_error={true}
        name={`${sideEffect}.actionArgs.value`}
        label={`Quantity`}
        type='number'
        step='1'
        component={renderInput}
        pattern='[0-9]*'
        inputMode='numeric'
        normalize={(val) => (val || '').replace(/[^\d]/g, '')}
      />
    );
  }
  if (actionName === 'showNotification') {
    otherDetailsComponent = (
      <Field noFormGroup={true} asCol={false} hideLabel={true} no_separate_error={true} name={`${sideEffect}.actionArgs.msg`} label={`Message`} type='text' component={renderInput} />
    );
  }

  return (
    <>
      <Row className={'action_row p-1'} style={{ justifyContent: 'space-between', margin: 0 }}>
        <Col className={'action_definition'}>
          {actorComponent}

          {actionComponent}

          {actionName && whatComponent}

          {createNewComponent}

          {otherDetailsComponent}
        </Col>
        <Col lg={'auto'}>
          <FieldArrayButtons rowName={'side effect'} fields={fields} index={index} />
        </Col>
      </Row>
    </>
  );
};
SideEffect2 = connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.formName);
  return {
    // selectedElementId: selector(state, `${ownProps.sideEffect}.elementId`),
    // selectedElementType: selector(state, `${ownProps.sideEffect}.elementType`),
    actionName: selector(state, `${ownProps.sideEffect}.actionName`),
    actionArgsWhat: selector(state, `${ownProps.sideEffect}.actionArgs.what`),
    numberPropHow: selector(state, `${ownProps.sideEffect}.actionArgs.numberPropHow`),
    conversationStatements: selector(state, 'conversation.statements'),
    archetypePayloadSpec: selector(state, 'payloadSpec'),
    archetypePickable: selector(state, 'pickable'),
    archetypeDestroyable: selector(state, 'destroyable'),
  };
})(SideEffect2);

export default SideEffect2;
