import React from 'react';

import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import GoogleMapLocationForm from '../gmap/GoogleMapLocationForm';
import { SCENE_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';
import ImageChoose from './ImageChoose';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormButtons from './FormButtons';

import { g4mFormValidate, g4mFormTotalFormValidate, asyncValidateForm } from '../utils/validationUtils';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import { useTranslation } from 'react-i18next';
import renderCheckbox2 from './renderers/renderCheckbox2';

const selector = formValueSelector(FORM_NAME);

const SceneForm = ({ error, dispatch, handleSubmit, onSubmit, handleCancel, initialValues, logoId, handleDelete }) => {
  const { t } = useTranslation();

  const onMarkerDrag = (lat, lng) => {
    dispatch(change(FORM_NAME, 'lat', lat));
    dispatch(change(FORM_NAME, 'lng', lng));
  };
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };

  //                        <Field name="description" type="textarea" label="Enter description" component={renderInput} />
  return (
    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
      <Row>
        <Col lg={7}>
          <Field name='name' label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />

          <Field asCol={false} name='notes' type='textarea' label='Notes' tooltip={t('NOTES_FIELD_TOOLTIP')} component={renderInput} />
          <Field name='title' label='Title' asCol={false} component={renderInput} />
          <Form.Row>
            <Col lg={10} md={9} sm={8}>
              <Field label='Description' name='description' component={RichTextEditorWYSIWYG} />
            </Col>
            <Col lg={2} md={3} sm={4}>
              <Field
                name='logoId'
                label='Media'
                logoIdToShow={logoId}
                handleRemoveMedia={() => dispatch(change(FORM_NAME, 'logoId', null))}
                gameId={initialValues && initialValues.gameId}
                handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'logoId', newId))}
                imageWidth={300}
                component={ImageChoose}
              />
            </Col>
          </Form.Row>
          <Field inline={false} component={renderCheckbox2} name={`enabled`} type='checkbox' label={`This scene is initially enabled`} props={{}} />
          <Field
            inline={false}
            component={renderCheckbox2}
            name={`fgOnly`}
            type='checkbox'
            label={`Foreground only`}
            tooltip={'Players cross this scene ONLY IF they have the app open and foregrounded'}
            props={{}}
          />
        </Col>
        <Col lg={5}>
          <Form.Row>
            <Field asCol={true} name='lat' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Latitude' type='number' step='0.001' component={renderInput} />
            <Field asCol={true} name='lng' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Longitude' type='number' step='0.001' component={renderInput} />
            <Field asCol={true} name='radius' label='Radius (m)' type='number' step='10' component={renderInput} />
          </Form.Row>
          <GoogleMapLocationForm style={{ height: '500px' }} formName={FORM_NAME} lat={initialValues.lat} radius={initialValues.radius} lng={initialValues.lng} onMarkerDrag={onMarkerDrag} />
        </Col>
      </Row>

      <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
    </Form>
  );
};

const validate = (formValues, donotknow) => {
  const errors = {};
  g4mFormValidate(errors, formValues, ['title', 'radius']); // name is validated asynchronously
  g4mFormTotalFormValidate(errors);
  //console.log('Scene Form validation errors: ', errors);
  return errors;
};

// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
//  })(GameForm);

export default connect((state, ownProps) => {
  return {
    logoId: selector(state, 'logoId'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
    asyncValidate: asyncValidateForm,
    asyncBlurFields: ['name'],
  })(SceneForm)
);
