import React, {useRef} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {createMedia} from "../../actions/mediasActions";


const MediaUpload = ({ gameId, dispatch, createMedia}) => {
    const fileInput = useRef(null);
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (fileInput.current.files.length > 0) {
            const formData = new FormData()
            Object.keys(fileInput.current.files).forEach((key) => {
                const file = fileInput.current.files[key];
                formData.append(key, new Blob([file], { type: file.type }), file.name || 'file')
            })
            dispatch(createMedia(formData, gameId))
                .then(() => {
                    console.log('Finished upload');
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }
    }
    const clearSelection = (event) => {
        event.preventDefault();
        //fileInput.current.removeFiles()

    }
    if (!gameId) {
        return <p>No game id</p>
    }
    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <h3>Upload media</h3>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs="auto">
                                            <Form.File accept="image/png, image/jpeg, audio/*, video/*" multiple ref={fileInput} />
                                        </Form.Group>
                                        <Form.Group as={Col} xs="auto">
                                            <Button  variant="primary" type="submit">
                                                Upload
                                            </Button>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="auto">
                                            <Button  variant="secondary" onClick={clearSelection}>
                                                Clear selection
                                            </Button>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>

                </Col>
            </Row>
        </Container>
    );
};

//export default MediaUpload;

// const mapStateToProps = (state, ownProps) => {
//     return {game:state.games[ownProps.gameidToEdit]};
// };
const mapStateToProps = (state, ownProps) => {
    return {};
};
export default connect(mapStateToProps,
    (dispatch) => ({dispatch, createMedia: createMedia}))(MediaUpload);
