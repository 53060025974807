import authapi from '../apis/authapi';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from './types';

/*
export const apitest = async () => {
    const response = await authapi.get(`/api/users/profile`);
    console.log(response);

}
export const apitest2 = async () => {
    const response = await authapi.get(`/api/users/apitest`);
    console.log(response);

}
export const apitest3 = async () => {
    const response = await authapi.get(`/api/play/games/?mode=full`);
    console.log(response);

}
*/

const setLoginData = (res) => {
  const { token } = res.data;
  localStorage.setItem('jwtToken', token);
  // Set token to Auth header
  setAuthToken(token);
  // Decode token to get user data
  return jwt_decode(token);
};

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  authapi
    .post('/api/users/register', userData)
    .then((res) => {
      // history.push("/login"); // re-direct to login on successful register
      // const decoded = setLoginData(res);
      // dispatch(setCurrentUser(decoded));
      // console.log("REGISTER SUCCESS WITH DECODED");
      // console.log(decoded);
      history.push('/registersuccess');
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const unRegisterUser = (history) => (dispatch) => {
  authapi
    .delete('/api/users/account')
    .then((res) => {
      localStorage.removeItem('jwtToken');
      setAuthToken(false);
      dispatch(setCurrentUser({}));
      history.push('/');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  authapi
    .post('/api/users/login', userData)
    .then((res) => {
      const decoded = setLoginData(res);
      dispatch(setCurrentUser(decoded));
      console.log('LOGIN SUCCESS WITH DECODED');
      console.log(decoded);
    })
    .catch((err) => {
      console.log('LOGIN ERROR RESPONSE');
      console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : { generalError: 'General login error ' + (err && err.response && err.response.statusText ? err.response.statusText : '') },
      });
    });
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
