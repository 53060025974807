import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  INSTANCE_PROP_EMAIL,
  INSTANCE_PROP_IMAGE,
  INSTANCE_PROP_NUMBER,
  INSTANCE_PROP_SOUND,
  INSTANCE_PROP_TEXTMULTI,
  INSTANCE_PROP_TEXTSINGLE,
  INSTANCE_PROP_VIDEO,
  INSTANCE_PROP_MEDIA,
} from './components/archetypes/archetypeConstants';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',

      NAME_FIELD_TOOLTIP: 'This name is used only internally to distinguish the various game elements. It must be unique for each type of game elements.',
      STATEMENT_OPTION_IF_TOOLTIP: 'The current option will be available only if these conditions are met. If no conditions are set, the option will always be available.',
      STATEMENT_OPTION_ACTION_TOOLTIP: 'What actions should be performed when the user chooses the current option',
      ACCOMPLISHMENT_TYPE_TOOLTIP: 'This defines the type of event that defines how the current mission is considered accomplished',
      CAPTURE_NOW_TOOLTIP: 'Requires the player to do the required action now',
      NAME_FIELD_LABEL: 'System name',
      NOTES_FIELD_TOOLTIP: 'Notes visible only to you',
      OBJECT_RADIUS_TOOLTIP: 'Refers to the radius in which this entity will be sensed or acted by the player (e.g. tracked, picked, destroyed, etc.',
      INSTANCE_PROP_TEXTSINGLE: 'Text - single',
      INSTANCE_PROP_EMAIL: 'Email address',
      INSTANCE_PROP_NUMBER: 'Number',
      INSTANCE_PROP_TEXTMULTI: 'Text - multi line',
      INSTANCE_PROP_IMAGE: 'Image',
      INSTANCE_PROP_SOUND: 'Audio',
      INSTANCE_PROP_VIDEO: 'Video',
      INSTANCE_PROP_MEDIA: 'Any Media',
      CONDITION_TYPE_PLAYER: 'Player behavior',
      CONDITION_TYPE_PLAYER_VERB_INFO: 'Player [VERB] info [INFO]',
      CONDITION_TYPE_PLAYER_VERB_CONFIRMATION: 'Player [VERB] confirmation [CONFIRMATION]',
      CONDITION_TYPE_PLAYER_VERB_QUESTION: 'Player [VERB] question [QUESTION]',
      CONDITION_TYPE_PLAYER_VERB_QUIZ: 'Player [VERB] quiz [QUIZ]',
      CONDITION_TYPE_PLAYER_VERB_QUESTION_QUIZ: 'Player [VERB] question [QUESTION] of quiz [QUIZ]',
      CONDITION_TYPE_PLAYER_VERB_SOME_QUIZ: 'Player [VERB] [SOME] questions of quiz [QUIZ]',
      CONDITION_TYPE_PLAYER_VERB_MISSION: 'Player [VERB] mission [MISSION]',
      CONDITION_TYPE_PLAYER_VERB_SCENE: 'Player [VERB] scene [SCENE]',
      CONDITION_TYPE_PLAYER_VERB_LOCATION: 'Player [VERB] place [PLACE]',
      CONDITION_TYPE_PLAYER_VERB_SOME_ARCHETYPE: 'Player [VERB] [SOME] objects of type [OBJECT]',
      CONDITION_TYPE_PLAYER_VERB_SOME_ITEM: 'Player [VERB] [SOME] items of type [ITEM]',
      CONDITION_TYPE_PLAYER_VERB_CODE: 'Player [VERB] code [CODE]',
      CONDITION_TYPE_PLAYER_VERB_ATTRIBUTE: 'Player [VERB] attribute [ATTRIBUTE]',
      CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY: 'Player [VERB] [SOME] currency',
      CONDITION_TYPE_PLAYER_VERB_CONVERSATION: 'Player [VERB] conversation [CONVERSATION]',
      CONDITION_TYPE_PLAYER_VERB_CHARACTER: 'Player [VERB] to character [CHARACTER]',
      CONDITION_TYPE_PLAYER_VERB_CODE_TRIGGER: 'Player [VERB] code [CODE]',
      CONDITION_TYPE_PLAYER_VERB_GAME: 'Player [VERB] game',
      CONDITION_TYPE_PLAYER_VERB_CURRENT_OBJECT: 'Player [VERB] this object',

      CONDITION_TYPE_GROUP: 'Group behavior',

      CONDITION_TYPE_OFFER: 'Offer',

      CONDITION_TYPE_OTHER: 'Other behavior',
      CONDITION_TYPE_OTHER_VERB_MILESTONE: '[VERB] milestone [MILESTONE]',

      CONDITION_TYPE_WORLD: 'World behavior',
      CONDITION_TYPE_WORLD_VERB_ATTRIBUTE: 'World [VERB] [ATTRIBUTE]',
      CONDITION_TYPE_WORLD_VERB_SOME_ITEM: 'World [VERB] [SOME] items [ITEM]',
      CONDITION_TYPE_WORLD_VERB_SOME_ARCHETYPE: 'World [VERB] [SOME] objects of type [OBJECT]',

      CONDITION_TYPE_GAME: 'Game behavior',
      CONDITION_TYPE_GAME_VERB_STATUS: 'Game [VERB] [STATUS]',
      CONDITION_TYPE_OFFER_KEY_PREDICATE_VALUE: 'Object [KEY] [PREDICATE] [VALUE]',
      CONDITION_TYPE_OFFER_VERB_ATTRIBUTE: 'Object [VERB] [ATTRIBUTE]',

      has_seen: 'has seen',
      has_seen_TRIGGER: 'has seen',
      not_has_seen: 'has not seen',
      has_approved: 'has approved',
      has_approved_TRIGGER: 'has approved',
      not_has_approved: 'has rejected',
      not_has_approved_TRIGGER: 'has rejected',
      has_been_asked: 'has been asked',
      not_has_been_asked: 'has not been asked',
      has_answered: 'has answered',
      not_has_answered: 'has not answered',
      has_passed: 'has passed',
      has_passed_TRIGGER: 'has passed',
      not_has_passed: 'has not passed',
      has_failed: 'has failed',
      has_failed_TRIGGER: 'has failed',
      not_has_failed: 'has not failed',
      has_started: 'has started',
      not_has_started: 'has not started',
      has_completed: 'has completed',
      has_completed_TRIGGER: 'has completed',
      not_has_completed: 'has not completed',
      has_been_assigned: 'has been assigned',
      not_has_been_assigned: 'has not been assigned',
      has_entered: 'has entered',
      has_entered_TRIGGER: 'enters',
      not_has_entered: 'has not entered',
      has_exited: 'has exited',
      has_exited_TRIGGER: 'leaves',
      not_has_exited: 'has not exited',
      is_currently_in: 'is currently in',
      not_is_currently_in: 'is not currently in',
      has_visited: 'has visited',
      has_visited_TRIGGER: 'has approached',
      has_typedscannedcode_TRIGGER: 'has typed or scanned',
      not_has_visited: 'has not visited',
      owns: 'owns',
      has_created: 'has created',
      has_picked: 'has picked',
      not_has_picked: 'has not picked',
      has_dropped: 'has dropped',
      has_destroyed: 'has destroyed',
      has: 'has',
      not_has: 'has not',
      is: 'is',
      not_is: 'is not',
      had: 'had',
      has_talked: 'has talked',
      not_has_talked: 'has not talked',
      has_reached: 'has reached',
      has_reached_TRIGGER: 'has been reached',
      not_owns: 'does not own',

      INTER_INSPECT_INFO: 'Info Boxes',
      INTER_ANSWER_QUESTION: 'Questions',
      INTER_CONFIRM: 'Confirmations',
      INTER_QUIZ: 'Quizes',
      INTER_CONVERSATION: 'Conversations',

      INTER_INSPECT_INFO_singular: 'Info Box',
      INTER_ANSWER_QUESTION_singular: 'Question',
      INTER_CONFIRM_singular: 'Confirmation',
      INTER_QUIZ_singular: 'Quiz',
      INTER_CONVERSATION_singular: 'Conversation',

      actiongroup_currentArchetypeActions: 'Interact with current object',
      actiongroup_currentConversationActions: 'Evolve current conversation',
      actiongroup_communicationActions: 'Communicate with player',
      actiongroup_playerState: "Modify player's state",
      actiongroup_worldState: "Modify worlds's state",
      actiongroup_evolveGame: 'Evolve game',

      action_pickObject: 'picks object',
      action_pickObjectClone: 'picks a clone',
      action_mutateObject: 'mutates object',
      action_destroyObject: 'destroys object',
      action_showObject: 'shows object',

      action_sayStatement: 'says statement',
      action_exitConversation: 'exits conversation',
      action_completeConversation: 'completes conversation',

      action_assignmission: 'assigns Mission',
      action_archivemission: 'archives Mission',

      action_giveToPlayer: 'gives to player',
      action_takeFromPlayer: 'takes from player',
      action_setOnPlayer: 'sets on player',
      action_removePlayerObjects: 'removes player objects',
      action_giveToWorld: 'gives to world',
      action_takeFromWorld: 'takes from world',
      action_setOnWorld: 'sets on world',

      action_attachToPlayer: 'attaches to player',
      action_detatchFromPlayer: 'detaches from player',
      action_attachToWorld: 'attaches to world',
      action_detatchFromWorld: 'detaches from world',
      action_returnToWorld: 'returns to world',
      action_removeFromWorld: 'removes to world',
      action_attachToObject: 'attaches to object',
      action_detatchFromObject: 'dettaches from object',

      action_showInfo: 'shows info',
      action_showNotification: 'shows notification',
      action_askQuestion: 'asks question',
      action_askQuiz: 'asks quiz',
      action_askConfirmation: 'asks for confirmation',
      action_startConversation: 'starts conversation',
      action_resumeConversation: 'resumes conversation',
      action_assignMission: 'assigns mission',
      action_archiveMission: 'archives mission',
      action_endGame: 'completes the game',
      action_endGame_MULTI: 'completes the game (for all)',
      action_completeGame: 'completes the game (for player)',
      action_enableScene: 'enables Scene',
      action_disableScene: 'disables Scene',

      milestones_breadobjecttype: 'milestone',
      scenes_breadobjecttype: 'scene',
      interactions_breadobjecttype: 'element',
      missions_breadobjecttype: 'mission',
      acrhetypes_breadobjecttype: 'archetype',
      items_breadobjecttype: 'item',
      characters_breadobjecttype: 'character',
      attributes_breadobjecttype: 'attribute',
      gameVersions_breadobjecttype: 'publish',

      SIDEEFFECT_ARCHETYPE_SELF: 'Current Archetype',
      SIDEEFFECT_CONVERSATION_SELF: 'Current Conversation',

      QTYPE_MC: 'Multiple choice',
      QTYPE_openEnded: 'Open ended',
      DTYPE_number: 'Number',
      DTYPE_stext: 'Single line text',
      DTYPE_mtext: 'Multi line text',

      'SHOW_TYPE_live-only': 'Of Working Version',
      'SHOW_TYPE_published-only': 'Of Public Version',
      SHOW_TYPE_both: 'Of All Versions',
      /*
            'TRIGGER_TAB_HEADING_EVENT_SCENE':'Scene',
            'TRIGGER_TAB_HEADING_EVENT_LOCATION':'Location',
            'TRIGGER_TAB_HEADING_EVENT_CODE_READ':'Code',
            'TRIGGER_TAB_HEADING_EVENT_MILESTONE_REACHED':'Milestone',
            'TRIGGER_TAB_HEADING_EVENT_ACTION_COMPLETED':'Action',
            'TRIGGER_EVENT_SCENE_ENTER':'Enter Scene',
            'TRIGGER_EVENT_SCENE_EXIT':'Leave Scene',
            'TRIGGER_EVENT_CODE_READ_USER':'User typed code',
            'TRIGGER_EVENT_CODE_READ_QR':'QR Code scaned',
            'TRIGGER_EVENT_LOCATION_ENTER':'Approached',
            'TRIGGER_EVENT_LOCATION_EXIT':'Left',
            'TRIGGER_EVENT_ACTION_COMPLETED_MISSION':'Mission',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUESTION':'Question',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUIZ':'Quiz',
            'TRIGGER_EVENT_ACTION_COMPLETED_CONFIRMATION':'Confirmation',
            'TRIGGER_EVENT_ACTION_COMPLETED_DISCUSSION':'Discussion',
            'TRIGGER_EVENT_ACTION_COMPLETED_INFO':'Info',
            'TRIGGER_EVENT_ACTION_COMPLETED_MISSION_ACCOMPLISHED':'Mission accomplished',
            'TRIGGER_EVENT_ACTION_COMPLETED_MISSION_ASSIGNED':'Mission assigned',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUESTION_ASKED':'Question asked',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUESTION_FAILED':'Question failed',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUESTION_PASSED':'Question passed',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUIZ_COMPLETED':'Quiz completed',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUIZ_STARTED':'Quiz started',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUIZ_SKIPPED':'Quiz skipped',
            'TRIGGER_EVENT_ACTION_COMPLETED_QUIZ_ASKED':'Quiz asked',
            'TRIGGER_EVENT_ACTION_COMPLETED_CONFIRMATION_ACCEPTED':'Confirmation accepted',
            'TRIGGER_EVENT_ACTION_COMPLETED_CONFIRMATION_REJECTED':'Confirmation rejected',
            'TRIGGER_EVENT_ACTION_COMPLETED_DISCUSSION_COMPLETED':'Discussion completed',
            'TRIGGER_EVENT_ACTION_COMPLETED_DISCUSSION_STARTED':'Discussion started',
            'TRIGGER_EVENT_ACTION_COMPLETED_INFO_SHARED':'Info shared',
            'TRIGGER_EVENT_ACTION_COMPLETED_INFO_SHOWN':'Info shown',
            'TRIGGER_EVENT_ACTION_COMPLETED_INFO_CAPTURED':'Info captured',
            'TRIGGER_EVENT_ACTION_COMPLETED_INFO_SEEN':'Info seen',*/
    },
  },
  el: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next',
      NAME_FIELD_TOOLTIP: 'Αυτό το όνομα χρησιμοποιείται μόνο εσωτερικά για το διαχωρισμό των διαφόρων στοιχείων του παιχνιδιού. Πρέπει να είναι μοναδικό για κάθε τύπο στοιχείου παιχνιδιού.',
      NAME_FIELD_LABEL: 'Όνομα συστήματος',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
