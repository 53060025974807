import React from 'react';

import {Field, FieldArray, formValueSelector} from 'redux-form';
import {connect, useSelector} from "react-redux";
import '../../i18n';
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import renderError from "./renderers/renderError";
import SideEffect2 from "./SideEffect2";

const renderSideEffects = ({ fields, meta, newInteractionHandler, gameItems, gameAttributes,
                               gameArchetypes, gameInteractions, gameScenes, gameCharacters,
                               gameMissions, formName, forArcheType, isElse, forConversation, currentStatementIndex, isMultiplayer }) => {
    return    (
        <>
            {renderError(meta, true)}
            <Button className="mb-2" onClick={() => fields.push({})}><i className="fas fa-plus"></i> Add Action</Button>
            <ul className={"p-0 m-0"}>
                {fields.map(
                    (sideEffect, index) =>
                        <li key={index} className="border p-1 mb-1 rounded">
                            <SideEffect2 sideEffect={sideEffect} fields={fields} formName={formName}
                                        items={gameItems} newInteractionHandler={newInteractionHandler} gameInteractions={gameInteractions}
                                        archetypes={gameArchetypes} attributes={gameAttributes} characters={gameCharacters}
                                        scenes={gameScenes}
                                         isMultiplayer={isMultiplayer}
                                        missions={gameMissions} index={index} forArcheType={forArcheType} isElse={isElse} forConversation={forConversation} currentStatementIndex={currentStatementIndex}/>
                        </li>
                )}
            </ul>
        </>
    )

};
const SideEffectForm2 = ({dispatch, fieldName, game, formName, newInteractionHandler, forArcheType, isElse, forConversation, currentStatementIndex}) => {
    const { t, i18n } = useTranslation();
    let allItems = useSelector((state)=>state.items);
    let allArchetypes = useSelector((state)=>state.archetypes);
    let allAttributes = useSelector((state)=>state.attributes);
    let allInteractions = useSelector((state)=>state.interactions);
    let allCharacters = useSelector((state)=>state.characters);
    let allMissions = useSelector((state)=>state.missions);
    let allScenes = useSelector((state)=>state.scenes);
    //let allMilestones = useSelector((state)=>state.milestones);
    //let allLocations = useSelector((state)=>state.locations);

    let gameInteractions = Object.values(allInteractions).filter(function (el) {return el.gameId === game.id;});
    let gameItems = Object.values(allItems).filter(function (el) {return el.gameId === game.id;});
    let gameArchetypes = Object.values(allArchetypes).filter(function (el) {return el.gameId === game.id;});
    let gameAttributes = Object.values(allAttributes).filter(function (el) {return el.gameId === game.id;});
    let gameCharacters = Object.values(allCharacters).filter(function (el) {return el.gameId === game.id;});
    let gameMissions = Object.values(allMissions).filter(function (el) {return el.gameId === game.id;});
    let gameScenes = Object.values(allScenes).filter(function (el) {return el.gameId === game.id;});
    //let gameMilestones = Object.values(allMilestones).filter(function (el) {return el.gameId === game.id;});
    //let sceneLocations = Object.values(allLocations).filter(function (el) {return el.sceneId === scene.id;});
    return (
        <div>
            <div>
                {
                    // ta to props prop, pernietai ws props sto component renderSideEffects
                }
                <FieldArray name={fieldName} component={renderSideEffects}
                            props={
                                {
                                    newInteractionHandler:newInteractionHandler,
                                    gameItems:gameItems,
                                    gameArchetypes:gameArchetypes,
                                    gameAttributes:gameAttributes,
                                    gameInteractions:gameInteractions,
                                    gameCharacters:gameCharacters,
                                    gameMissions:gameMissions,
                                    gameScenes:gameScenes,
                                    formName:formName,
                                    forArcheType:forArcheType,
                                    forConversation:forConversation,
                                    currentStatementIndex:currentStatementIndex,
                                    isElse:isElse?true:false,
                                    isMultiplayer:game.multiplayer
                                }
                            } />

            </div>
        </div>
    );

}

export default SideEffectForm2;


