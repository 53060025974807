import React  from 'react';
import { getTotalCount } from '../../utils/utils';

const QuizzesSummaryWidget = ({ publishedGameVersion, analytics }) => {
  return (
    <>
      <h5>Quizzes</h5>
      <ul className="list-group mt-3">
        {publishedGameVersion.game.spec.resources.quizzes.map(q => {
          return (
            <li
              key={q.id}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              {q.title}
              <small className="font-sm text-right">
                Answered: <strong>{getTotalCount(analytics,`has.answered.quiz.${q.id}`)}</strong>
                {' / '}
                Completed: <strong>{getTotalCount(analytics,`has.completed.quiz.${q.id}`)}</strong>
              </small>
            </li>
          )
        })}
      </ul>
    </>
  );
};

export default QuizzesSummaryWidget;
