//import _ from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {editRule, createRule, deleteRule} from '../../actions/rulesActions';

import RuleForm from "../forms/RuleForm";
import history from "../../history";
import MilestoneForm from "../forms/MilestoneForm";
import {bconfirm} from "../utils/ConfirmAlert";

const RuleEdit = ({dispatch, rule, game, scene, onCancel, onSave, afterDelete}) => {
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
            return;
        }
        history.push(`/games/${game.id}/scenes/show/${scene.id}`); // if no specific handler is used, redirect to scene design page
    };
    const handleDelete = async (id) => {
        const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
        if (result) {
            dispatch(deleteRule(id))
                .then(() => {
                    if (afterDelete) {
                        afterDelete();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}/scenes/show/${scene.id}`); // if no specific handler is used, redirect to scene design page
                    }
                })
                .catch((e) => {
                    /* do nothing. A toastr notification should have been displayed */
                });
        }
    }
    const onSubmit = (formValues) => {
        console.log('RuleEdit onSubmit');

        if (rule) {
            dispatch(editRule(rule.id, formValues))
                .then(() => {
                    if (onSave) {
                        onSave();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}/scenes/show/${scene.id}`); // if no specific handler is used, redirect to scene design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }
        else {
            dispatch(createRule(game.id, formValues))
                .then(() => {
                    if (onSave) {
                        onSave();
                        return;
                    }
                    else {
                        history.push(`/games/${game.id}/scenes/show/${scene.id}`); // if no specific handler is used, redirect to scene design page
                    }
                })
                .catch((e) => {
                    /* do nothing. An error toastr should have been displayed */
                });
        }

    };

    if (!game || !scene) {
        return <>Waiting for game or scene data...</>;
    }
    const defaultInitialValues = {
        sceneId:scene.id,
        gameId:game.id,
        fireOnce:true
    };

    return (
        <div className="">

            <RuleForm
                enableReinitialize={true}
                handleCancel={handleCancel}
                handleDelete={handleDelete}

                initialValues={rule?rule:defaultInitialValues}
                onSubmit={onSubmit}
                scene={scene}
                game={game}
            />
            {
                // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
            }
        </div>
    );

}

export default connect(null,null)(RuleEdit);
