import gamesApi from '../../apis/gamesApi';

import { actionsThatDoNotNeedWhat, blockingActions, itemPlayerActions, itemWorldActions } from './sideeffectConstants';
import { INTER_CONVERSATION } from '../interactions/interactionTypes';

import { QTYPE_MC, QTYPE_OPEN } from '../interactions/interactionConstants';
import store from './../../store';
import { getSelectedPayload } from './variousUtils';

import { useSelector } from 'react-redux';
import { INSTANCE_PROP_TEXTMULTI, INSTANCE_PROP_TEXTSINGLE, INSTANCE_PROP_NUMBER } from '../archetypes/archetypeConstants'; // we need it just to get the interaction type for sideeffects
const isEmpty = require('is-empty');

const accomplishmentValidation = (formValuesElement) => {
  let errors = null;
  if (!formValuesElement || !formValuesElement.accomplishmentType) {
    errors = {};
    errors.accomplishmentType = 'Required';
  } else {
    switch (formValuesElement.accomplishmentType) {
      case 'action':
        if (!formValuesElement.action || !formValuesElement.action.type) {
          if (!errors) {
            errors = {};
          }
          errors.action = {};
          errors.action.type = 'Required';
        } else {
          const mediatypemediaRequired = formValuesElement.action['mediatypemediaRequired'];
          const mediatypetextRequired = formValuesElement.action['mediatypetextRequired'];
          if (!mediatypemediaRequired && !mediatypetextRequired) {
            if (!errors) {
              errors = {};
            }
            errors.action = {};
            errors.action['mediatypemediaRequired'] = 'Check at least one required capture/share type';
          } else {
            if (mediatypemediaRequired) {
              // at least one possible media type must be checked
              const possibleMediaChecked = [];
              ['mediatypeimage', 'mediatypevideo', 'mediatypeaudio'].forEach((propName, propNameNdx) => {
                if (formValuesElement.action[propName]) {
                  possibleMediaChecked.push(propName);
                }
              });
              if (possibleMediaChecked.length === 0) {
                if (!errors) {
                  errors = {};
                }
                errors.action = {};
                errors.action.mediatypeimage = 'Check at least one possible media type';
              }
            }
          }
        }
        break;
      case 'condition':
        if (!formValuesElement.condition || formValuesElement.condition.length === 0) {
          errors = {};
          errors.condition = {};
          errors.condition._error = 'You must add at least one condition block';
        } else {
          const conditionPropArrayErrors = conditionValidation(formValuesElement.condition);
          if (conditionPropArrayErrors.length) {
            if (!errors) {
              errors = {};
            }
            errors.condition = conditionPropArrayErrors;
          }
        }
        break;
      case 'submissions':
        if (!formValuesElement.submissions || formValuesElement.submissions.length === 0) {
          errors = {};
          errors.submissions = {};
          errors.submissions._error = 'You must add at least one sub mission';
        } else {
          formValuesElement.submissions.forEach((submission, submissionNdx) => {
            const submissionFormValues = formValuesElement.submissions[submissionNdx];
            const submissionErrors = {};
            g4mFormValidate(submissionErrors, submissionFormValues, ['title', 'description', 'accomplishment']); // the same as in MissionForm
            if (Object.keys(submissionErrors).length > 0) {
              if (!errors) {
                errors = {};
              }
              if (!errors.submissions) {
                errors.submissions = [];
              }
              errors.submissions[submissionNdx] = submissionErrors;
            }
          });
        }
        break;
      default: {
      }
    }
  }
  return errors;
};
const directionValidation = (formValue) => {
  if (!formValue || !formValue.isDirection) {
    return null;
  }
  const errors = {};
  if (!formValue.title) {
    errors.title = 'required';
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }
  return null;
};
const limitValidation = (formValue) => {
  if (!formValue || !formValue.enabled) {
    return null;
  }
  const errors = {};
  if (!formValue.maxItems) {
    errors.maxItems = 'required';
  }
  if (!formValue.timeUnit || formValue.timeUnit === '') {
    errors.timeUnit = 'required';
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }
  return null;
};

const conditionElementValidation = (condition) => {
  if (!condition) return null;
  let conditionOfBlockErrors = null;
  if (!condition.conditionType) {
    if (!conditionOfBlockErrors) {
      conditionOfBlockErrors = {};
    }
    conditionOfBlockErrors.conditionType = 'Required';
  } else {
    if (isEmpty(condition.verb)) {
      if (!conditionOfBlockErrors) {
        conditionOfBlockErrors = {};
      }
      conditionOfBlockErrors.verb = 'Required';
    }
    if (condition.conditionType.includes('SOME')) {
      if (isEmpty(condition.some)) {
        if (!conditionOfBlockErrors) {
          conditionOfBlockErrors = {};
        }
        conditionOfBlockErrors.some = 'Required';
      }
      if (isEmpty(condition.someNumber)) {
        if (!conditionOfBlockErrors) {
          conditionOfBlockErrors = {};
        }
        conditionOfBlockErrors.someNumber = 'Required';
      }
    }
    if (condition.conditionType.includes('QUIZ') && condition.conditionType.includes('QUESTION')) {
      if (isEmpty(condition.subElementId)) {
        if (!conditionOfBlockErrors) {
          conditionOfBlockErrors = {};
        }
        conditionOfBlockErrors.subElementId = 'Required';
      }
    }
    if (
      condition.conditionType !== 'CONDITION_TYPE_PLAYER_VERB_SOME_CURRENCY' &&
      condition.conditionType !== 'CONDITION_TYPE_PLAYER_VERB_CODE' &&
      condition.conditionType !== 'CONDITION_TYPE_PLAYER_VERB_GAME' &&
      condition.conditionType !== 'CONDITION_TYPE_PLAYER_VERB_CURRENT_OBJECT' &&
      condition.conditionType !== 'CONDITION_TYPE_GAME_VERB_STATUS' &&
      isEmpty(condition.elementId)
    ) {
      if (!conditionOfBlockErrors) {
        conditionOfBlockErrors = {};
      }
      conditionOfBlockErrors.elementId = 'Required';
    }
  }
  return conditionOfBlockErrors;
};
const addError = (errorObject, propertyName, errorValue) => {
  if (!errorObject) {
    errorObject = {};
  }
  errorObject[propertyName] = errorValue;
  return errorObject;
};
const optionValidation = (option, modifier, fullElement) => {
  let optionPropErrors = null;
  if (isEmpty(option.actionLabel)) {
    optionPropErrors = addError(optionPropErrors, 'actionLabel', 'required');
  }
  // condition
  // actions
  const conditionPropArrayErrors = conditionValidation(option.condition);
  if (conditionPropArrayErrors.length) {
    optionPropErrors = addError(optionPropErrors, 'condition', conditionPropArrayErrors);
  }

  const sideeffectsArrayErrors = actionsValidation(option.actions, modifier, fullElement);
  if (sideeffectsArrayErrors) {
    optionPropErrors = addError(optionPropErrors, 'actions', sideeffectsArrayErrors);
  }

  const actionsPropArrayErrors = conditionValidation(option.actions);
  if (conditionPropArrayErrors.length) {
    optionPropErrors = addError(optionPropErrors, 'condition', conditionPropArrayErrors);
  }
  return optionPropErrors;
};
const statementValidation = (statement, modifier, fullElement) => {
  // options
  let statementPropErrors = null;
  if (!statement) {
    // should never happen
    return { description: 'required' };
  }
  if (isEmptyMarkup(statement.description)) {
    statementPropErrors = addError(statementPropErrors, 'description', 'required');
  }

  const directionErrors = directionValidation(statement.direction);
  if (directionErrors) {
    statementPropErrors = addError(statementPropErrors, 'direction', directionErrors);
  }

  if (isEmpty(statement.options) || statement.options.length < 1) {
    statementPropErrors = addError(statementPropErrors, 'options', []);
    statementPropErrors.options._error = 'You need to add at least 1 user option for this statement';
  }
  if (statement.options) {
    statement.options.forEach((option, optionIndex) => {
      const optionPropErrors = optionValidation(option, modifier, fullElement);
      if (!isEmpty(optionPropErrors)) {
        if (isEmpty(statementPropErrors)) {
          statementPropErrors = {};
        }
        if (isEmpty(statementPropErrors.options)) {
          statementPropErrors.options = [];
        }
        statementPropErrors.options[optionIndex] = optionPropErrors;
      }
    });
  }
  return statementPropErrors;
};

const conversationValidation = (formValuesElement, modifier, fullElement) => {
  let conversationPropArrayErrors = null;
  if (isEmpty(formValuesElement) || isEmpty(formValuesElement.statements) || formValuesElement.statements.length < 2) {
    const conversationPropArrayErrors = { statements: [] };
    conversationPropArrayErrors.statements._error = 'You need to add at least 2 statements';
    return conversationPropArrayErrors;
  }

  formValuesElement.statements.forEach((statement, statementIndex) => {
    const statementPropErrors = statementValidation(statement, modifier, fullElement);
    if (!isEmpty(statementPropErrors)) {
      if (isEmpty(conversationPropArrayErrors)) {
        conversationPropArrayErrors = {};
      }
      if (isEmpty(conversationPropArrayErrors.statements)) {
        conversationPropArrayErrors.statements = [];
      }
      conversationPropArrayErrors.statements[statementIndex] = statementPropErrors;
    }
  });
  return conversationPropArrayErrors;
};

const actionsValidation = (formValuesElement, modifier, fullElement) => {
  const sideeffectsArrayErrors = [];
  let countBlockingSideeffects = 0;
  if (formValuesElement && formValuesElement.length) {
    formValuesElement.forEach((sideeffect, sideeffectIndex) => {
      const sideeffectErrors = {};
      let errorPresent = false;
      if (sideeffect) {
        if (sideeffect.actorId === 'empty') {
          // no select has as value "empty" string
          sideeffectErrors.actorId = 'Required';
          errorPresent = true;
        }
        if (!sideeffect.actionName || sideeffect.actionName === '') {
          errorPresent = true;
          sideeffectErrors.actionName = 'Required';
        }
        if (sideeffect.actionName && !actionsThatDoNotNeedWhat.includes(sideeffect.actionName) && (!sideeffect.actionArgs || !sideeffect.actionArgs.what)) {
          errorPresent = true;
          if (!sideeffectErrors.actionArgs) {
            sideeffectErrors.actionArgs = {};
          }
          sideeffectErrors.actionArgs.what = 'required';
        }
        if (sideeffect.actionName === 'showNotification' && (!sideeffect.actionArgs || !sideeffect.actionArgs.msg || sideeffect.actionArgs.msg.trim() === '')) {
          errorPresent = true;
          if (!sideeffectErrors.actionArgs) {
            sideeffectErrors.actionArgs = {};
          }
          sideeffectErrors.actionArgs.msg = 'required';
        }

        if (sideeffect.actionName && blockingActions.includes(sideeffect.actionName)) {
          countBlockingSideeffects++;
        }

        if (sideeffect.actionName && (itemWorldActions.includes(sideeffect.actionName) || itemPlayerActions.includes(sideeffect.actionName))) {
          if (!sideeffect.actionArgs || !sideeffect.actionArgs.value || sideeffect.actionArgs.value === '') {
            errorPresent = true;
            if (!sideeffectErrors.actionArgs) {
              sideeffectErrors.actionArgs = {};
            }
            sideeffectErrors.actionArgs.value = 'Required';
          }
        }

        // // for some interactions, e.g. the actor is required ---> it is required for all actions now and is checked above
        // if (
        //     sideeffect.elementType === ELEMENT_TYPE_INTERACTION
        // ) {
        //     const state = store.getState();
        //     const interactions = state.interactions;
        //     //console.log('state.interactions IS ',state.interactions);
        //
        //     if (sideeffect.elementId && interactions) {
        //         const selectedElementObject = interactions[sideeffect.elementId];
        //         if (selectedElementObject) { // at this point this should always be available
        //             if (selectedElementObject.type === INTER_CONVERSATION && !sideeffect.actorId) {
        //                 errorPresent = true;
        //                 sideeffectErrors.actorId = 'Required';
        //             }
        //         }
        //     }
        // }

        // empty values for .actionName have been already handled
        if (sideeffect.actionName === 'mutateObject') {
          // archetype mutateobject
          // validation depends on the type of payload or property that is being changed
          // what: must be not empty (---> is already checked above. Now it must be set for all actions)
          // value: true/false for what = visible/trackable
          // value: number for number payload
          // value: text (even empty?? for text payload)
          // numberPropHow: not empty for number payload
          let payload = null;
          // we must have some value in actionArgs.value, unless what is a text type payload
          if (sideeffect.actionArgs.what !== 'perception.visible' && sideeffect.actionArgs.what !== 'perception.trackable') {
            payload = getSelectedPayload(sideeffect.actionArgs.what, fullElement.payloadSpec);
          }

          // we allow empty values for text
          if (
            sideeffect.actionArgs.what === 'perception.visible' ||
            sideeffect.actionArgs.what === 'perception.trackable' ||
            (payload && payload.type !== INSTANCE_PROP_TEXTSINGLE && payload.type !== INSTANCE_PROP_TEXTMULTI)
          ) {
            if (!sideeffect.actionArgs.value || isEmpty(sideeffect.actionArgs.value) || sideeffect.actionArgs.value === '') {
              errorPresent = true;
              if (!sideeffectErrors.actionArgs) {
                sideeffectErrors.actionArgs = {};
              }
              sideeffectErrors.actionArgs.value = 'required';
            }
          }

          if (payload && payload.type === INSTANCE_PROP_NUMBER) {
            if (!sideeffect.actionArgs || !sideeffect.actionArgs.numberPropHow) {
              errorPresent = true;
              if (!sideeffectErrors.actionArgs) {
                sideeffectErrors.actionArgs = {};
              }
              sideeffectErrors.actionArgs.numberPropHow = 'required';
            }
          }

          //console.log('action with mutateObject, fullElement:',fullElement);
        }
      }

      if (errorPresent) {
        sideeffectsArrayErrors[sideeffectIndex] = sideeffectErrors;
      }
    }); // end for each
  } else {
    if (modifier === 'atleastone') {
      sideeffectsArrayErrors._error = 'You need at least one action';
    }
  }
  //console.log(`countBlockingSideeffects: ${countBlockingSideeffects}`);
  if (countBlockingSideeffects > 1) {
    sideeffectsArrayErrors._error = 'You can have at most 1 blocking action';
  }
  if (sideeffectsArrayErrors.length || countBlockingSideeffects > 1 || sideeffectsArrayErrors._error) {
    return sideeffectsArrayErrors;
  }
  return null;
};
const conditionValidation = (formValuesElement) => {
  const conditionPropArrayErrors = [];
  if (formValuesElement && formValuesElement.length) {
    let errorPresent = false;
    formValuesElement.forEach((conditionBlock, conditionBlockIndex) => {
      const conditionPropErrors = {};
      if (!conditionBlock.conditions || conditionBlock.conditions.length === 0) {
        if (!conditionPropErrors.conditions) {
          conditionPropErrors.conditions = [];
        }
        errorPresent = true;
        conditionPropErrors.conditions._error = 'Add at least one condition to this block';
      } else {
        conditionBlock.conditions.forEach((condition, conditionIndex) => {
          const conditionOfBlockErrors = conditionElementValidation(condition);
          if (conditionOfBlockErrors !== null) {
            errorPresent = true;
            if (!conditionPropErrors.conditions) {
              conditionPropErrors.conditions = [];
            }
            conditionPropErrors.conditions[conditionIndex] = conditionOfBlockErrors;
          }
        });
      }
      if (errorPresent) {
        conditionPropArrayErrors[conditionBlockIndex] = conditionPropErrors;
      }
      //console.log('dfgkdfjghkdjfhgkd hgkjhd fkjghdk f:',conditionPropArrayErrors)
    });
  }
  return conditionPropArrayErrors;
};

const removeTags = (markup) => {
  if (!markup) {
    return null;
  }
  return markup.replace(/(<([^>]+)>)/gi, '');
};
const isEmptyMarkup = (markup) => {
  let stripped = removeTags(markup);
  if (stripped != null) {
    stripped = stripped.trim();
  }
  if (stripped === null || typeof stripped === 'undefined' || stripped === '') {
    return true;
  }
  return false;
};
export const g4mFormValidate = (errors, formValues, fieldNames) => {
  if (!Array.isArray(fieldNames)) {
    fieldNames = [fieldNames];
  }

  fieldNames.every((element, index, array) => {
    // check if the fieldname has a modifier
    let modifier = null;
    let parts = element.split(':');
    if (parts.length === 2) {
      element = parts[0];
      modifier = parts[1];
    }
    switch (element) {
      case 'title':
      case 'titleSecondary':
      case 'buttonLabel':
      case 'playgroundtitle':
      case 'description':
      case 'descriptionSecondary':
      case 'buttonLabelSecondary':
      case 'buttonLabelTertiary':
      case 'mediaId':
      case 'mediaIdSecondary':
      case 'actionLabelAct':
      case 'actionLabelContact':
      case 'logoId':
      case 'minAge':
      case 'language':
        if (!formValues[element]) {
          errors[element] = 'Required';
        }
        if (modifier == 'wysiwyg' && formValues[element]) {
          if (isEmptyMarkup(formValues[element])) {
            errors[element] = 'Required';
          }
        }

        break;
      // case 'name': // name field is validated asynchronously
      case 'maxAge':
        if (formValues['minAge'] && formValues['maxAge']) {
          if (formValues['maxAge'] <= formValues['minAge']) {
            errors[element] = 'Must be > min age';
          }
        }
        break;
      case 'adultsOnly':
        if (formValues['adultsOnly']) {
          if (formValues['minAge'] < 18) {
            errors['minAge'] = 'Must be >= 18';
          }
        }
        break;
      case 'radius':
        if (!formValues[element]) {
          errors[element] = 'You must set a radius';
        }
        break;
      case 'questions':
        const questionsPropArrayErrors = [];
        if (formValues[element]) {
          formValues[element].forEach((question, questionIndex) => {
            const questionErrors = {};
            let errorPresent = false;
            // if (!question.mediaId) {
            //     errorPresent = true;
            //     questionErrors.mediaId = 'Required';
            // }
            if (!question.title) {
              errorPresent = true;
              questionErrors.title = 'Required';
            }
            if (isEmptyMarkup(question.description)) {
              errorPresent = true;
              questionErrors.description = 'Required';
            }
            if (!question.qType) {
              errorPresent = true;
              questionErrors.qType = 'Required';
            }
            if (question.qType === QTYPE_OPEN) {
              if (!question.dType) {
                errorPresent = true;
                questionErrors.dType = 'Required';
              }
            }
            let countCorrectAnswer = 0;
            if (question.qType === QTYPE_MC) {
              if (!question.options || question.options.length < 2) {
                errorPresent = true;
                if (!questionErrors.options) {
                  questionErrors.options = [];
                }
                questionErrors.options._error = 'You need to add at least 2 options';
              }

              const optionPropArrayErrors = [];
              if (question.options) {
                question.options.forEach((option, optionIndex) => {
                  const optionErrors = {};
                  let optionErrorPresent = false;
                  if (!option.description || !option.description.trim()) {
                    optionErrors.description = 'Required';
                    optionErrorPresent = true;
                  }
                  if (option.partOfCorrect) {
                    countCorrectAnswer++;
                  }
                  if (optionErrorPresent) {
                    optionPropArrayErrors[optionIndex] = optionErrors;
                  }
                });
              }
              if (optionPropArrayErrors.length > 0) {
                errorPresent = true;
                questionErrors.options = optionPropArrayErrors;
              }
            }

            if (question.hasCorrectAnswer) {
              switch (question.qType) {
                case QTYPE_OPEN:
                  if (!question.correctAnswer) {
                    errorPresent = true;
                    questionErrors.correctAnswer = 'Required';
                  }
                  break;
                case QTYPE_MC:
                  if (!question.allowMultipleAnswers) {
                    if (countCorrectAnswer != 1 && !questionErrors.options?._error) {
                      errorPresent = true;
                      if (!questionErrors.options) {
                        questionErrors.options = [];
                      }
                      questionErrors.options._error = 'You need to set 1 option as correct';
                    }
                  } else {
                    if (countCorrectAnswer < 1 && !questionErrors.options?._error) {
                      errorPresent = true;
                      if (!questionErrors.options) {
                        questionErrors.options = [];
                      }
                      questionErrors.options._error = 'You need to set at least 1 option as correct';
                    }
                  }
                  break;
                default:
              }
            }
            if (errorPresent) {
              questionsPropArrayErrors[questionIndex] = questionErrors;
            }
          });

          if (modifier === 'single') {
            if (!formValues[element] || formValues[element].length !== 1) {
              questionsPropArrayErrors._error = 'Add one question';
            }
          } else {
            if (!formValues[element] || formValues[element].length === 0) {
              questionsPropArrayErrors._error = 'Add at least one question';
            }
          }
          if (questionsPropArrayErrors.length || questionsPropArrayErrors._error) {
            errors[element] = questionsPropArrayErrors;
          }
        }

        break;
      case 'accomplishment':
        const accomplishmentErrors = accomplishmentValidation(formValues[element]);
        if (accomplishmentErrors) {
          errors[element] = accomplishmentErrors;
        }
        break;
      case 'sideeffects':
      case 'sideeffectselse':
      case 'sideeffectsContact':
      case 'sideeffectsAct':
      case 'sideeffectselseContact':
      case 'sideeffectselseAct':
        const sideeffectsArrayErrors = actionsValidation(formValues[element], modifier, formValues);
        if (sideeffectsArrayErrors) {
          errors[element] = sideeffectsArrayErrors;
        }
        break;
      case 'condition':
      case 'conditionCreation':
      case 'conditionPerception':
      case 'conditionContact':
      case 'conditionAct':
      case 'triggerPreconditions':
      case 'if':
        const conditionPropArrayErrors = conditionValidation(formValues[element]);
        if (conditionPropArrayErrors.length) {
          errors[element] = conditionPropArrayErrors;
        }
        break;
      case 'conversation':
        const conversationPropArrayErrors = conversationValidation(formValues[element], modifier, formValues);
        if (!isEmpty(conversationPropArrayErrors)) {
          errors[element] = conversationPropArrayErrors;
        }
        break;
      case 'playerGenerated':
        if (formValues[element]) {
          // if playergenerated is true, check validate limit
          const limitErrors = limitValidation(formValues['limitCreation']);
          if (limitErrors) {
            errors['limitCreation'] = limitErrors;
          }
        }
        break;
      case 'pickable':
        if (formValues[element]) {
          // if pickable is true, check validate limit
          const limitErrors = limitValidation(formValues['limitPicks']);
          if (limitErrors) {
            errors['limitPicks'] = limitErrors;
          }
        }
        break;
      case 'direction':
        const directionErrors = directionValidation(formValues[element]);
        if (directionErrors) {
          errors['direction'] = directionErrors;
        }
        break;
      case 'destroyable':
        if (formValues[element]) {
          // if destroyable is true, check validate limit
          const limitErrors = limitValidation(formValues['limitDestroys']);
          if (limitErrors) {
            errors['limitDestroys'] = limitErrors;
          }
        }
        break;
      case 'trigger':
        if (typeof formValues[element] === 'undefined') {
          errors[element] = { conditionType: 'Required' }; // special handling here
        } else {
          const conditionOfBlockErrors = conditionElementValidation(formValues[element]);
          if (conditionOfBlockErrors) {
            errors[element] = conditionOfBlockErrors;
          }
        }
        break;
      default:
    }
    return true; // I have to return true, otherwise every() does not continue
  });
};

export const g4mFormTotalFormValidate = (errors) => {
  if (Object.keys(errors).length > 0) {
    errors._error = 'Please correct the errors in the form';
  }
};

export const asyncValidateForm = async (values, dispatch, props, blurredField) => {
  console.log('asyncValidateForm ');
  const data = await gamesApi.post(`/api/design/utils/asyncValidateForm?gameId=${values.gameId}`, { values: values, activeForm: props.form, blurredField: blurredField });
  if (!data.data.success) {
    // throw { name: `Username ${values.name} is already taken.`, title:'skata', _error:'skata2'};
    console.log('asyncValidateForm throwing errors', data);
    throw data.data.errors;
  } else {
    console.log('asyncValidateForm ALL OK', data);

    // do nothing
  }
};

/* This was the initial form validation by Stefanos. I changed it with the next function */
export const validateObjectBehaviorSK = (errors, formValues) => {
  const { sideeffectsContact = [], sideeffectselseContact = [], sideeffectsAct = [], sideeffectselseAct = [] } = formValues;
  const checks = [
    {
      behavior: 'pickable',
      requirement: 'include-some',
      actionNames: ['pickObject', 'pickObjectClone'],
      actionLabels: ['picks object', 'picks a clone'],
    },
    {
      behavior: 'redropable',
      requirement: 'exclude-all',
      actionNames: ['pickObjectClone'],
      actionLabels: ['picks a clone'],
    },
    {
      behavior: 'destroyable',
      requirement: 'include-all',
      actionNames: ['destroyObject'],
      actionLabels: ['destroys object'],
    },
  ];

  checks.every((check) => {
    const actionExists = []
      .concat(sideeffectsAct)
      .concat(sideeffectsContact)
      .find((action) => action.actionName === check.actionName);
    if (formValues[check.behavior]) {
      if (!actionExists) {
        errors['sideeffectsAct'] = {
          _error: `You must include the Action "${check.actionLabel}" in the object behavior...`,
        };
        errors['sideeffectsContact'] = {
          _error: `You must include the Action "${check.actionLabel}" in the object behavior...`,
        };
        return false;
      }
    } else {
      if (actionExists) {
        errors['sideeffectsAct'] = {
          _error: `Action "${check.actionLabel}" is not allowed in object behavior...`,
        };
        errors['sideeffectsContact'] = {
          _error: `Action "${check.actionLabel}" is not allowed in object behavior...`,
        };
        return false;
      }
    }
    return true;
  });
};

export const validateObjectBehavior = (errors, formValues) => {
  const { sideeffectsContact = [], sideeffectselseContact = [], sideeffectsAct = [], sideeffectselseAct = [] } = formValues;
  const checks = [
    {
      behavior: 'pickable',
      mutuallyInclusive: true,
      requirement: 'include-some',
      actionNames: ['pickObject', 'pickObjectClone'],
      actionLabels: ['picks object', 'picks a clone'],
    },
    {
      behavior: 'redropable',
      requirement: 'exclude-all',
      actionNames: ['pickObjectClone'],
      actionLabels: ['picks a clone'],
    },
    {
      behavior: 'destroyable',
      mutuallyInclusive: true,
      requirement: 'include-all',
      actionNames: ['destroyObject'],
      actionLabels: ['destroys object'],
    },
  ];
  const allScriptActionNames = []
    .concat(
      sideeffectsAct.map((sef) => {
        return sef.actionName;
      })
    )
    .concat(
      sideeffectsContact.map((sef) => {
        return sef.actionName;
      })
    );
  checks.every((check) => {
    const hasCheckBehavior = !!formValues[check.behavior];
    let checkPass = true;
    let mutualError = false;
    if (check.mutuallyInclusive) {
      if (hasCheckBehavior && check.requirement === 'include-all') {
        checkPass = check.actionNames.every((actionName) => allScriptActionNames.includes(actionName));
      } else if (hasCheckBehavior && check.requirement === 'exclude-all') {
        checkPass = !check.actionNames.some((actionName) => allScriptActionNames.includes(actionName));
      } else if (hasCheckBehavior && check.requirement === 'include-some') {
        checkPass = check.actionNames.some((actionName) => allScriptActionNames.includes(actionName));
      }
    }
    if (check.mutuallyInclusive && !hasCheckBehavior) {
      const someActionIsUsed = check.actionNames.some((actionName) => allScriptActionNames.includes(actionName));
      checkPass = !someActionIsUsed;
      mutualError = true;
    }

    if (checkPass) {
      return true;
    }
    let errorMsg = '';
    if (mutualError) {
      errorMsg = `Action(s): [${check.actionLabels.join(', ')}] are only allowed when Behaviour "${check.behavior.toUpperCase()}" is enabled`;
    } else {
      errorMsg =
        check.requirement === 'include-some'
          ? `Object behavior "${check.behavior.toUpperCase()}" needs (one of) the action(s): [${check.actionLabels.join(', ')}]`
          : check.requirement === 'include-all'
          ? `Behavior "${check.behavior.toUpperCase()}" needs the action(s): [${check.actionLabels.join(', ')}]`
          : `Behavior "${check.behavior.toUpperCase()}" is incompatible with action(s): [${check.actionLabels.join(', & ')}]`;
    }
    errors['sideeffectsAct'] = {
      _error: errorMsg,
    };
    errors['sideeffectsContact'] = {
      _error: errorMsg,
    };
    return false;
  });
};
