import React  from 'react';
import { getProperty } from 'dot-prop';
import { Popover, OverlayTrigger } from "react-bootstrap"

import { formatDate, getTotalCount, extractData } from '../../utils/utils';

const InfosSummaryWidget = ({ publishedGameVersion, analytics }) => {
  return (
    <>
      <h5>Infos</h5>
      <ul className="list-group mt-3">
        {publishedGameVersion.game.spec.resources.infos.map(i => {
          return (
            <li
              key={i.id}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              {i.title}
              <small className="font-sm text-right">
                Seen by
                {' '}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="right"
                  overlay={
                    <Popover content>
                      <ul className="list-group list-group-flush ">
                        {extractData(analytics, `has.seen.info.${i.id}`).length === 0 && (
                          <li
                            className="list-group-item"
                          >
                            -
                          </li>
                        )}
                        {extractData(analytics, `has.seen.info.${i.id}`).map(userState => {
                          return (
                            <li
                              key={userState.userId}
                              className="list-group-item"
                            >
                              {(userState.avatar && userState.avatar.name) || '-'}

                              <small className="d-block text-muted">
                                { formatDate(getProperty(userState, `has.seen.info.${i.id}.when`))}
                              </small>
                            </li>
                          );
                        })}
                      </ul>
                    </Popover>
                  }
                >
                  <a>
                    <strong>{getTotalCount(analytics,`has.seen.info.${i.id}`)} players</strong>
                  </a>
                </OverlayTrigger>
              </small>
            </li>
          )
        })}
      </ul>
    </>
  );
};

export default InfosSummaryWidget;
