/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchMyGames, deleteGame, cloneGame, unpublishGame, archiveGame } from '../../actions/gamesActions';
//import {apitest, apitest2, apitest3} from '../../actions/authActions';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//import {bconfirm} from '../utils/ConfirmAlert';
import GameEdit from './GameEdit';
import MainContent from '../layouts/MainContent';
import ModalEdit from '../utils/ModalEdit';
import { shortenText } from '../../utils/utils';
import { getThumbUrlById } from '../utils/mediaUtils';
import { Alert, Dropdown, DropdownButton, SplitButton, Toast } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { bconfirm } from '../utils/ConfirmAlert';
import { Link } from 'react-router-dom';

const GameList = ({ app, games, currentUserId, isAuthenticated, errors, fetchMyGames, deleteGame, dispatch }) => {
  const [openEditGameModal, setOpenEditGameModal] = useState(false);
  const [selectedGameIdToEdit, setSelectedGameIdToEdit] = useState({});

  useEffect(() => {
    dispatch(fetchMyGames());
  }, [isAuthenticated, currentUserId, app, dispatch, fetchMyGames]);

  const handleCloneGame = async (gameId) => {
    const doClone = await bconfirm('Are you sure you want to Clone this Game?');
    if (doClone) {
      dispatch(cloneGame(gameId));
    }
  };
  const handleUnpublishGame = async (gameId) => {
    const doClone = await bconfirm('Are you sure you want to Unpublish this Game?');
    if (doClone) {
      dispatch(unpublishGame(gameId));
    }
  };

  const handleDeleteGame = async (gameId) => {
    const doDelete = await bconfirm('Operation cannot be Undone. All game data will be removed. Do you want to proceed?');
    if (doDelete) {
      dispatch(deleteGame(gameId));
    }
  };

  const handleArchiveGame = async (gameId) => {
    const doArchive = await bconfirm('Operation cannot be Undone. Do you want to proceed?');
    if (doArchive) {
      dispatch(archiveGame(gameId));
    }
  };

  const renderList = () => {
    if (!games || games.length === 0) {
      return <>No games</>;
    }
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href='#'
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}>
        {children}
        <i className='fa fa-bars'></i>
      </a>
    ));
    return games.map((game) => {
      const logoUrl = getThumbUrlById(game.logoId, true);
      return (
        <Card key={game.id} className='mt-3 shadow-sm'>
          <Card.Header className='d-flex bg-white'>
            <span
              className='material-icons-outlined m-icon'
              title={game.publicVersionId ? 'Published' : 'Not Published'}
              onMouseOver={() => {
                console.log('Mouse Over!!!');
              }}>
              {game.publicVersionId ? 'visibility' : 'visibility_off'}
            </span>
            <span className='ml-2 font-weight-bold'>{game.title}</span>
            <Row className='ml-auto mr-1'>
              <Dropdown>
                <Dropdown.Toggle drop='left' variant='primary' id='dropdown-basic' as={CustomToggle}></Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Divider /> */}
                  <Dropdown.Item
                    onSelect={() => {
                      handleCloneGame(game.id);
                    }}>
                    <span className='material-icons-outlined m-icon'>content_copy</span> Clone Game
                  </Dropdown.Item>
                  {!game.publicVersionId && (
                    <Dropdown.Item
                      onSelect={() => {
                        handleDeleteGame(game.id);
                      }}>
                      <span className='material-icons-outlined m-icon'>delete_outline</span> Delete Game
                    </Dropdown.Item>
                  )}
                  {game.publicVersionId && (
                    <Dropdown.Item
                      eventKey='3'
                      onSelect={() => {
                        handleUnpublishGame(game.id);
                      }}>
                      <span className='material-icons-outlined m-icon'>visibility_off</span> Unpublish Game
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    eventKey='3'
                    onSelect={() => {
                      handleArchiveGame(game.id);
                    }}>
                    <span className='material-icons-outlined m-icon'>archive</span> Archive Game
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={'auto'}>
                <Image src={logoUrl} thumbnail width={100} />
              </Col>
              <Col>
                <Card.Text>{shortenText(game.description, 400, 'No description')}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className='d-flex p-2 pr-2 flex-row justify-content-between'>
            <div>
              <LinkContainer to={`/games/${game.id}`}>
                <Button className={'mr-1'}>
                  <i className='fas fa-magic'></i> Design
                </Button>
              </LinkContainer>
              <LinkContainer variant='warning' to={`/games/edit/${game.id}`}>
                <Button className={'mr-1'}>
                  <i className='fas fa-cog'></i> Settings
                </Button>
              </LinkContainer>
            </div>
            <div>
              <LinkContainer to={`/active-games/${game.id}`}>
                <Button variant='outline-info' className={'mr-1'}>
                  <i className='fas fa-cog'></i> Gameplay
                </Button>
              </LinkContainer>
              <LinkContainer to={`/analytics/${game.id}`}>
                <Button variant='outline-secondary' className={'mr-1'}>
                  <>
                    <span className='material-icons-outlined m-icon'>donut_small</span> Analytics
                  </>
                </Button>
              </LinkContainer>
            </div>
          </Card.Footer>
        </Card>
      );
    });
  };
  // const openGameModal = () => {
  //     setSelectedGameId(-1);
  //     setOpenAddGameModal(true);
  // };
  const closeEditGameModal = () => {
    // setOpenAddGameModal(false);
    setOpenEditGameModal(false);
    //getData();
  };
  // const closeDeleteGameModal = () => {
  //     setOpenDeleteGameModal(false);
  // };
  // const cancelDeleteGameModal = () => {
  //     setOpenDeleteGameModal(false);
  //     // setOpenAddGameModal(false);
  //
  // };
  // const cancelAddGameModal = () => {
  //     setOpenAddGameModal(false);
  // };
  // const cancelEditGameModal = () => {
  //     setOpenEditGameModal(false);
  // };
  const cancelEditGameModal = () => {
    setOpenEditGameModal(false);
    // setOpenAddGameModal(false);
  };
  const editGame = (gameId) => {
    setSelectedGameIdToEdit(gameId);
    //setCurrentContact(contact);
    setOpenEditGameModal(true);
  };

  // const editGameHandler = gameidToEdit => {
  //     setSelectedGameId(gameidToEdit);
  //     setCurrentGameId(gameidToEdit);
  //     setOpenEditGameModal(true);
  // };

  return (
    <>
      <MainContent hasSideBar={false}>
        <Row>
          <Col xs={'auto'}>
            {
              // <Test someprop={1}></Test>
            }
            <LinkContainer to='/games/new'>
              <Button className={'mr-2'}>
                <i className='fas fa-plus'></i> New Game
              </Button>
            </LinkContainer>
            {/*<Button  variant="outline-primary" onClick={()=>editGame(-1)}><i*/}
            {/*    className="fas fa-plus"></i> New Game Modal</Button>*/}
          </Col>
        </Row>
        {renderList()}
      </MainContent>

      <ModalEdit openEditModal={openEditGameModal} closeEditModal={closeEditGameModal} heading={'Edit Game'} portal='game'>
        {
          // to onSave htan
          // onSave={closeEditGameModal.bind(this)}
          // kai to ekana sketo
          // onSave={closeEditGameModal}
          // fainetai na leitourgoun ola
        }
        <GameEdit gameidToEdit={selectedGameIdToEdit} onSave={closeEditGameModal} onCancel={cancelEditGameModal} />
      </ModalEdit>
    </>
  );
};

const mapStateToProps = (state) => {
  // to object.values pairnei ola ta properties enos object kai ta metatrepei ta values se array
  return {
    app: state.app,
    games: Object.values(state.games),
    currentUserId: state.auth.user.id,
    isAuthenticated: state.auth.isAuthenticated,
    errors: state.errors,
  };
};
//export default connect(mapStateToProps, {fetchMyGames, deleteGame})(GameList);
export default connect(mapStateToProps, (dispatch) => ({
  dispatch,
  fetchMyGames: fetchMyGames,
  deleteGame: deleteGame,
}))(GameList);
