import React, { useEffect } from 'react';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';

import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import qs from 'qs';
import { Col, Row } from 'react-bootstrap';
import { fetchGameInitialState } from '../../actions/initialStateActions';
import { fetchGameInitialStateSpec } from '../../actions/initialStateSpecActions';
import MarkerMap from '../gmap/MarkerMap';

const getSceneName = (scenes, id) => {
  const found = scenes.find((s) => s.id === id);
  return (found && found.name) || 'scene not available';
};

export const getExistingItemList = (initialState, scenes, pathType, game, type, versionIdQueryPart, gameSpec, verb, extras = {}) => {
  verb = verb || 'has';
  const { onItemClick, filterFn } = extras;
  const keys = type === 'objects' ? Object.keys(initialState.state[verb][pathType]) : Object.keys(initialState.state[verb][pathType]);
  if (keys.length === 0) {
    return null;
  }
  const itemList = [];
  keys.forEach((key) => {
    if (Object.values(initialState.state[verb][pathType][key]).length === 0) {
      return;
    }
    itemList.push(
      <li key={key}>
        {'Scene: '}
        <strong>{getSceneName(scenes, key)}</strong>
        {Object.values(initialState.state[verb][pathType][key]).length === 0 && (
          <>
            <br />
            <i>{`No ${type} added`}</i>
          </>
        )}
        <ul>
          {Object.values(initialState.state[verb][pathType][key]).map((obj) => {
            if (filterFn && typeof filterFn === 'function') {
              if (!filterFn(obj)) {
                return null;
              }
            }
            //console.log(`Object ${obj.name} has payload values: `, JSON.stringify(obj.payload['6659dc3c1ebc895daf771be0']));
            return (
              <li key={obj.id}>
                <LinkContainer
                  onClick={
                    onItemClick
                      ? (e) => {
                          e.preventDefault();
                          onItemClick(obj, type);
                        }
                      : null
                  }
                  to={`/games/${game.id}/initialState/edit/${obj.id}/${type}${versionIdQueryPart}`}
                  className=''>
                  <a>
                    {obj.iconUri && <img width={25} className='mr-2' src={obj.iconUri} />}
                    {type === 'objects' && getArchetypeNameByArchetypeId(obj.archetypeId, gameSpec)}
                    {type === 'offers' && getOfferNameByOfferId(obj.offerId, gameSpec)}
                    {': ' + obj.name}
                  </a>
                </LinkContainer>
              </li>
            );
          })}
        </ul>
      </li>
    );
  });

  return itemList;
};

const getArchetypeNameByArchetypeId = (id, gameSpec) => {
  const archSpec = gameSpec.spec.resources.archetypes.find((a) => a.id === id);
  return archSpec ? archSpec.name : '-';
};

const getOfferNameByOfferId = (id, gameSpec) => {
  const spec = gameSpec.spec.resources.offers.find((o) => o.id === id);
  return spec ? spec.name : '-';
};

export const getMap = (gameSpec, scenes, initialState, pathType, type, height) => {
  const markersData = [];
  if (!gameSpec || !gameSpec.spec || !gameSpec.spec.script || !gameSpec.spec.script.scenes) {
    return 'No scenes in game';
  }
  scenes.map((scene) => {
    const locationObject = {
      name: scene.name,
      lat: scene.place.center.latitude,
      lng: scene.place.center.longitude,
      radius: scene.place.radius,
      onlyRadius: true,
    };
    locationObject.typeLabel = 'Scene';
    markersData.push(locationObject);
  });

  Object.keys(initialState.state.has[pathType]).forEach((sceneId) => {
    Object.values(initialState.state.has[pathType][sceneId]).forEach((obj) => {
      const archetypeName = type === 'objects' ? obj.title : getOfferNameByOfferId(obj.offerId, gameSpec);
      const objectName = obj.name ?? obj.title;
      const locationObject = {
        name: objectName,
        lat: obj.location.latitude,
        lng: obj.location.longitude,
      };
      locationObject.iconUrl = obj.iconUri;
      locationObject.radiusColor = '#ffff00';
      locationObject.typeLabel = archetypeName;
      markersData.push(locationObject);
    });
  });

  return <MarkerMap markersData={markersData} height={height}></MarkerMap>;
};
const ManageInitialStateItems = ({ match, location, type, game, dispatch }) => {
  let gameSpec = useSelector((state) => state.initialStateSpec);
  let initialState = useSelector((state) => state.initialState);
  const scenes = gameSpec && gameSpec.spec && gameSpec.spec.script.scenes;
  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const versionId = queryString.versionId;
  const pathType = type === 'offers' ? 'offer' : 'object';
  const versionIdQueryPart = versionId ? `?versionId=${versionId}` : '';
  const resourceType = pathType === 'offer' ? 'offers' : 'archetypes';
  const types = useSelector((state) => state.initialStateSpec?.spec?.resources?.[resourceType] ?? []);

  useEffect(() => {
    dispatch(fetchGameInitialState(game.id, versionId || 'current'));
    dispatch(fetchGameInitialStateSpec(game.id, versionId || 'current'));
  }, [dispatch, game.id, versionId]);

  if (!gameSpec?.spec?.resources?.archetypes || !initialState?.state?.has[pathType]) {
    return <>Loading....</>;
  }
  return (
    <>
      <hr />
      <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
        Game World {type}{' '}
        {types.length > 0 && (
          <LinkContainer to={`/games/${game.id}/initialState/create/0/${type}${versionIdQueryPart}`} className='btn-success'>
            <a className='btn btn-primary'>Add {pathType}</a>
          </LinkContainer>
        )}
      </h2>
      <Row>
        <Col className='col-md-12 col-lg-5' style={{ height: '700px' }}>
          <ul className='scene-objects-list'>{getExistingItemList(initialState, scenes, pathType, game, type, versionIdQueryPart, gameSpec)}</ul>
        </Col>
        <Col className='col-md-12 col-lg-7'>{getMap(gameSpec, scenes, initialState, pathType, type, 700)}</Col>
      </Row>
    </>
  );
};

export default withRouter(connect(null, null)(ManageInitialStateItems));
