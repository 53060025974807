import { change, Field, formValueSelector } from 'redux-form';
import renderInput from './renderers/renderInput';
import { connect } from 'react-redux';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker'; // https://reactdatepicker.com/ https://github.com/Hacker0x01/react-datepicker
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import enUk from 'date-fns/locale/en-GB';
import moment from 'moment-timezone';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
registerLocale('enUk', enUk); // uk has first day of week Monday

let PlayingPeriod2 = ({ dispatch, reduxFieldName, formName, start, end, timezone, meta, isInline = true }) => {
  const { t, i18n } = useTranslation();
  const utcStringToTimezonedString = (utcDateTime, timezone) => {
    if (!timezone) {
      timezone = 'Europe/Athens';
    }
    // parses something like 2022-01-20T08:50:01Z
    // and outputs something like 2022-01-20 10:50:01 which would be the datetime in given timezone
    if (timezone && utcDateTime && moment(utcDateTime).isValid()) {
      return moment.tz(utcDateTime, timezone).format('YYYY-MM-DD HH:mm:ss');
    }
    return null;
  };
  const dateTimeStringToJsDate = (dateTimeString) => {
    // parses something like 2022-01-20 10:50:01 (no Z, no offset !!!. Can have a T between date and time), and changes it to JS DateTime. The DateTime will be in the browsers timezone)
    if (!dateTimeString) {
      return null;
    }
    let unixTime = Date.parse(dateTimeString);
    return new Date(unixTime);
  };
  //const [timezoneValue, setTimezoneValue] = useState(meta.initial && meta.initial.timezone? meta.initial.timezone:(moment.tz.guess()? moment.tz.guess():'Europe/Athens'));
  const [timezoneValue, setTimezoneValue] = useState(meta.initial && meta.initial.timezone ? meta.initial.timezone : null);
  const [startDate, setStartDate] = useState(dateTimeStringToJsDate(utcStringToTimezonedString(meta.initial ? meta.initial.start : null, timezoneValue)));
  const [endDate, setEndDate] = useState(dateTimeStringToJsDate(utcStringToTimezonedString(meta.initial ? meta.initial.end : null, timezoneValue)));

  const onChangeStart = (newValue) => {
    setStartDate(newValue);
    onChangeDate(newValue, 'start', timezoneValue);
  };
  const onChangeEnd = (newValue) => {
    setEndDate(newValue);
    onChangeDate(newValue, 'end', timezoneValue);
  };
  const onChangeDate = (newValue, reduxPropertyName, timezoneToUse) => {
    // new value will be a datetime JS object in browsers timezone.
    // I will extract the data and will treat it as date time in set timezone
    if (!newValue || newValue === '') {
      dispatch(change(formName, reduxFieldName + '.' + reduxPropertyName, null));
      return;
    }
    let year = newValue.getFullYear();
    let month = newValue.getMonth(); // zero based
    let day = newValue.getDate();

    let hours = newValue.getHours();
    let minutes = newValue.getMinutes();

    let dt = `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    //console.log(dt);
    let momentValue = moment.tz(dt, timezoneToUse);
    dispatch(change(formName, reduxFieldName + '.' + reduxPropertyName, momentValue.utc().format()));
  };
  const onChangeTimezone = (e) => {
    setTimezoneValue(e.target.value);
    dispatch(change(formName, reduxFieldName + '.timezone', e.target.value));
    // the utc value of start/end may have changed. Sto call them too
    onChangeDate(endDate, 'end', e.target.value);
    onChangeDate(startDate, 'start', e.target.value);
  };

  return (
    <Row>
      <Field component='input' type={'hidden'} name={reduxFieldName + '.start'}></Field>
      <Field component='input' type={'hidden'} name={reduxFieldName + '.end'}></Field>
      <Field component='input' type={'hidden'} name={reduxFieldName + '.timezone'}></Field>

      <Col lg={isInline ? 4 : 12}>
        <label>
          Start date and time
          <DatePicker
            className='form-control form-control'
            showTimeSelect
            showTimeInput
            selected={startDate}
            onChange={onChangeStart}
            locale={enUk}
            timeIntervals={10}
            dateFormat='dd/MM/yyyy HH:mm'
            monthsShown={1}
          />
        </label>
      </Col>
      <Col lg={isInline ? 4 : 12}>
        <label>
          End date and time
          <DatePicker
            className='form-control form-control'
            showTimeSelect
            showTimeInput
            selected={endDate}
            onChange={onChangeEnd}
            locale={enUk}
            timeIntervals={10}
            dateFormat='dd/MM/yyyy HH:mm'
            monthsShown={1}
          />
        </label>
      </Col>
      <Col lg={isInline ? 4 : 12}>
        <label>
          Timezone
          <select value={timezoneValue} onChange={onChangeTimezone} className='form-control form-control'>
            <option value={null}>Choose</option>
            {moment.tz.names().map((option) => (
              <option value={option}>{option}</option>
            ))}
          </select>
        </label>
      </Col>
      {meta.touched && meta.error && (
        <Col lg={12}>
          <Form.Text className='text-muted'>
            <span className={'red-text'}>{meta.error}</span>
          </Form.Text>
        </Col>
      )}
    </Row>
  );
};
PlayingPeriod2 = connect((state, ownProps) => {
  // const selector = formValueSelector(ownProps.formName);
  // return {
  //     start: selector(state, `${ownProps.reduxFieldName}.start`),
  //     end: selector(state, `${ownProps.reduxFieldName}.end`),
  //     timezone: selector(state, `${ownProps.reduxFieldName}.timezone`),
  // }
})(PlayingPeriod2);

export default PlayingPeriod2;
