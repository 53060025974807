import React, { useState }  from 'react';
import { getProperty } from 'dot-prop';
import { Popover, OverlayTrigger, Modal } from "react-bootstrap"

import { formatDate, getTotalCount, extractData } from '../../utils/utils';


const MissionsSummaryWidget = ({ publishedGameVersion, analytics }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [parentMission, setParentMission] = useState(null);

  const renderCapture = (captureData) => {
    const data = getProperty(captureData, 'capturedInfo') || getProperty(captureData, 'capturedShare');

    if (!data) {
      return '-';
    }

    return (
      <>
        {data && data.msg && data.msg.txt && (
          <p>
            {data && data.msg.txt}
          </p>
        )}

        {data && data.media && data.media.uri && (
          <a
            href={data.media.uri}
            target="_blank"
          >
            { data.media.type !== 'image' && `View ${data.media.type}`}

            { data.media.type === 'image' && (
              <img src={data.media.uri} height={100} />
            )}
          </a>
        )}
      </>
    )
  };

  return (
    <>
      <h5>Missions</h5>
      <ul className="list-group mt-3">
        {publishedGameVersion.game.spec.resources.missions.map(m => {
          return (
            <li
              key={m.id}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              <a
                className="text-primary"
                onClick={() => {
                  setModalOpen(true);
                  setModalData(m);
                }}
              >
                {m.title}
              </a>
              <small className="font-sm text-right">
                Assigned:
                {' '}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="right"
                  overlay={
                    <Popover content>
                      <ul className="list-group list-group-flush ">
                        {extractData(analytics, `has.been.assigned.mission.${m.id}`).length === 0 && (
                          <li
                            className="list-group-item"
                          >
                            -
                          </li>
                        )}
                        {extractData(analytics, `has.been.assigned.mission.${m.id}`).map(userState => {
                          return (
                            <li
                              key={userState.userId}
                              className="list-group-item"
                            >
                              {(userState.avatar && userState.avatar.name) || '-'}

                              <small className="d-block text-muted">
                                { formatDate(getProperty(userState, `has.been.assigned.mission.${m.id}.when`))}
                              </small>
                            </li>
                          );
                        })}
                      </ul>
                    </Popover>
                  }
                >
                  <a>
                    <strong>{getTotalCount(analytics, `has.been.assigned.mission.${m.id}`)}</strong>
                  </a>
                </OverlayTrigger>

                {' / '}
                Completed:
                {' '}
                <a
                  onClick={() => {
                    setModalOpen(true);
                    setModalData(m);
                  }}
                >
                  <strong className="text-primary">
                    {getTotalCount(analytics, `has.completed.mission.${m.id}`)}
                  </strong>
                </a>
              </small>
            </li>
          )
        })}
      </ul>

      <Modal
        size="lg"
        show={modalOpen}
        onHide={() => {
          setModalOpen(false);
          setParentMission(null);
        }}
        className="analytics"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {parentMission && ((
              <a
                className="border-right pr-2 mr-2"
                onClick={() => {
                  setModalData(parentMission);
                  setParentMission(null);
                }}
              >
                <i className="fas fa-arrow-left" />
              </a>
            ))}
            Mission: {modalData && modalData.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5>Description:</h5>
          {modalData && modalData.description}


          {modalData.accomplishment && modalData.accomplishment.missions && (
            <>
              <h5 className="mt-4">Sub-missions:</h5>
              {
                modalData.accomplishment
                && modalData.accomplishment.missions && (
                  <ul className="list-group">
                    {modalData.accomplishment.missions.map(m => {
                      return (
                        <li
                          key={m.id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <a
                            className="text-primary"
                            onClick={() => {
                              setParentMission({...modalData});
                              setModalData(m);
                            }}
                          >
                            {m.title}
                          </a>

                          <small className="font-sm text-right">
                            Assigned:
                            {' '}
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={
                                <Popover content>
                                  <ul className="list-group list-group-flush ">
                                    {extractData(analytics, `has.been.assigned.mission.${m.id}`).length === 0 && (
                                      <li
                                        className="list-group-item"
                                      >
                                        -
                                      </li>
                                    )}
                                    {extractData(analytics, `has.been.assigned.mission.${m.id}`).map(userState => {
                                      return (
                                        <li
                                          key={userState.userId}
                                          className="list-group-item"
                                        >
                                          {(userState.avatar && userState.avatar.name) || '-'}

                                          <small className="d-block text-muted">
                                            { formatDate(getProperty(userState, `has.been.assigned.mission.${m.id}.when`))}
                                          </small>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </Popover>
                              }
                            >
                              <a>
                                <strong>{getTotalCount(analytics, `has.been.assigned.mission.${m.id}`)}</strong>
                              </a>
                            </OverlayTrigger>
                            {' / '}
                            Completed:
                            {' '}
                            <a

                              onClick={() => {
                                setModalOpen(true);
                                setParentMission({...modalData});
                                setModalData(m);
                              }}
                            >
                              <strong
                                className="text-primary"
                              >
                                {getTotalCount(analytics, `has.completed.mission.${m.id}`)}
                              </strong>
                            </a>
                          </small>
                        </li>
                      );
                    })}
                  </ul>
                )
              }
            </>
          )}


          {
            modalData
            && modalData.id
            && !(modalData.accomplishment && modalData.accomplishment.missions)
            && (
              <>
                <h5 className="mt-4">Completed by:</h5>

                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Player</th>
                      <th>Captured data</th>
                      <th>When</th>
                    </tr>
                  </thead>

                  <tbody>
                    {extractData(analytics, `has.completed.mission.${modalData.id}`).map(userState => {
                      return (
                        <tr>
                          <td>
                            {(userState.avatar && userState.avatar.name) || '-'}
                          </td>
                          <td>
                            {renderCapture(getProperty(userState, `has.completed.mission.${modalData.id}.current`))}
                          </td>
                          <td>
                            {formatDate(
                              getProperty(userState, `has.completed.mission.${modalData.id}.when`)
                                || getProperty(userState, `has.completed.mission.${modalData.id}.current.when`)
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MissionsSummaryWidget;
