import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";


const Footer = () => {

    return (
        <footer className="">
            <Navbar bg="dark" expand="md" variant="dark" className={'fixed-bottom'}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="https://g4m.tuc.gr/" target={'_blank'}>About</Nav.Link>
                        <Nav.Link href="http://www.music.tuc.gr/projects/g4m/policy.html" target={'_blank'}>Privacy</Nav.Link>

                        {/*<Nav.Link href={`${process.env.REACT_APP_API_BASE}/site/privacy`}>Privacy</Nav.Link>*/}

                        {/*<LinkContainer to="/debugging-tools" >*/}
                        {/*  <Nav.Link>Debugging Tools</Nav.Link>*/}
                        {/*</LinkContainer>*/}

                    </Nav>
                    <Nav className="ml-auto">

                        {/*<Nav.Link href={`${process.env.REACT_APP_API_BASE}/site/privacy`}>Privacy</Nav.Link>*/}

                        {/*<LinkContainer to="/debugging-tools" >*/}
                        {/*  <Nav.Link>Debugging Tools</Nav.Link>*/}
                        {/*</LinkContainer>*/}
                        <a className="navbar-brand ml-auto" href="https://g4m.tuc.gr/" target="_blank" >
                            <img src={`${process.env.PUBLIC_URL}/resources/images/Logo_G4M_notext.svg`} alt="G4M Project" title="G4M Project" height="25" />
                        </a>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>

        </footer>
    );

};

export default Footer;
