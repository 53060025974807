import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import qs from 'qs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { io } from 'socket.io-client';
import ErrorBoundary from './ErrorBoundary';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Privacy from './static/Privacy';
import Register from './auth/Register';
import Login from './auth/Login';
import RegisterSuccess from './auth/RegisterSuccess';
import RegisterConfirm from './auth/RegisterConfirm';
import SocialRedirect from './auth/SocialRedirect';

import DebugginsTools from './games/DebugginsTools';
import ActiveGames from './games/ActiveGames';
import ActiveGame from './games/ActiveGame';
import GameAnalytics from './games/GameAnalytics';
import GameList from './games/GameList';
import GameDesign from './games/GameDesign';
//import GameCreate from "./games/GameCreate_OBSOLETE";
import GameEdit from './games/GameEdit';
import ScrollToTop from './utils/ScrollToTop';
import Button from 'react-bootstrap/Button';

//import { LinkContainer } from 'react-router-bootstrap';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';

import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../actions/authActions';

import history from '../history';
import { connect } from 'react-redux';
import store from '../store';
import MainContent from './layouts/MainContent';
import Forgot from './auth/Forgot';
import Resend from './auth/Resend';
import ForgotChange from './auth/ForgotChange';
import UserAccount from './users/UserAccount';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = './login';
  }
}

const Splash = () => {
  return (
    <Container>
      <Row className='justify-content-md-center pt-5'>
        <Col>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/rollick_icon_transparrent.svg`}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '80%',
              maxWidth: '400px',
            }}
            className=''
            alt='Rollick logo'
          />
        </Col>
      </Row>
      <Row className='justify-content-md-center  pt-1'>
        <Col xs lg='2'>
          &nbsp;
        </Col>
        <Col md='auto'>
          <LinkContainer to='/login' className={'mr-2'}>
            <Button>Sign in</Button>
          </LinkContainer>
          <LinkContainer to='/register'>
            <Button variant='outline-primary'>Sign up</Button>
          </LinkContainer>
        </Col>
        <Col xs lg='2'>
          &nbsp;
        </Col>
      </Row>
      <Row className='mb-5 pb-5'>
        <Col>&nbsp;</Col>
      </Row>
      <Row className='justify-content-md-center mt-5 pt-5'>
        <Col>
          <a href='https://g4m.tuc.gr/' target='_blank' rel='noopener nofollow noreferrer'>
            <img
              src={`${process.env.PUBLIC_URL}/resources/images/Logo_G4M_wide.svg`}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '80%',
                maxWidth: '120px',
                border: '1px solid #e3dada',
                padding: '5px',
              }}
              className=''
              alt='G4M logo'
              title={'G4M Project'}
            />
          </a>
        </Col>
        <Col>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/ep_kriti.png`}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '80%',
              maxWidth: '120px',
              border: '1px solid #e3dada',
              padding: '5px',
            }}
            className=''
            alt='G4M logo'
          />
        </Col>
        <Col>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/espa.png`}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '80%',
              maxWidth: '120px',
              border: '1px solid #e3dada',
              padding: '5px',
            }}
            className=''
            alt='G4M logo'
          />
        </Col>
        <Col>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/eu.png`}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '80%',
              maxWidth: '120px',
              border: '1px solid #e3dada',
              padding: '5px',
            }}
            className=''
            alt='G4M logo'
          />
        </Col>
      </Row>
    </Container>
  );
};

const socket = io(`${process.env.REACT_APP_API_BASE}?instanceId=60ae199450841a147c65d2dc`, {});

const G4m = ({ isAuthenticated }) => {
  return (
    <ErrorBoundary>
      <Router history={history}>
        <ScrollToTop />
        <Route
          path={[
            '/games/:id/:objecttype/:objectaction/:objectid',
            '/games/:id/:objecttype/:objectaction/:objectid/:modifier1',
            '/games/:id',
            '/games/edit/:id',
            '/active-games/:id',
            '/analytics/:id',
            '/active-games/:id/instance/:instanceId',
            '*',
          ]}
          exact
          render={({ match }) => {
            return <Header activeGameId={match.params.id} />;
          }}
        />
        <Container fluid className=' py-3' style={{ maxWidth: '1400px' }}>
          <div className='wrapper'>
            <Switch>
              <Route path='/register' exact component={Register} />
              <Route path='/registersuccess' exact component={RegisterSuccess} />
              <Route path='/confirmemail' exact component={RegisterConfirm} />
              <Route path='/forgot' exact component={Forgot} />
              <Route path='/resend' exact component={Resend} />
              <Route path='/forgotchange' exact component={ForgotChange} />
              <Route path='/login' exact component={Login} />
              <Route path='/socialredirect' exact component={SocialRedirect} />

              <Route
                path='/account'
                exact
                render={({ match }) => {
                  return isAuthenticated ? <UserAccount /> : <Splash />;
                }}
              />
              <Route
                path='/analytics/:activeGameId'
                exact
                render={({ match }) => {
                  return isAuthenticated ? <GameAnalytics /> : <Splash />;
                }}
              />

              <Route
                path='/active-games'
                exact
                render={({ match }) => {
                  return isAuthenticated ? <ActiveGames /> : <Splash />;
                }}
              />

              <Route
                path='/active-games/:activeGameId'
                exact
                render={({ match }) => {
                  return isAuthenticated ? <ActiveGames /> : <Splash />;
                }}
              />

              <Route
                path='/active-games/:activeGameId/instance/:instanceId'
                exact
                render={({ match }) => {
                  return isAuthenticated ? <ActiveGame /> : <Splash />;
                }}
              />

              <Route
                path='/debugging-tools'
                exact
                render={({ match }) => {
                  return isAuthenticated ? <DebugginsTools /> : <Splash />;
                }}
              />

              <Route
                path='/games/new'
                exact
                render={({ match }) => {
                  return (
                    <>
                      {isAuthenticated ? (
                        <MainContent header={'New Game'} hasSideBar={false}>
                          <GameEdit gameidToEdit={-1} />
                        </MainContent>
                      ) : (
                        <Splash />
                      )}
                    </>
                  );
                }}
              />

              <Route
                path='/games/edit/:id'
                exact
                render={({ match }) => {
                  return (
                    <>
                      {isAuthenticated ? (
                        <MainContent header={'Edit Game'} hasSideBar={false}>
                          <GameEdit gameidToEdit={match.params.id} />
                        </MainContent>
                      ) : (
                        <Splash />
                      )}
                    </>
                  );
                }}
              />

              <Route
                path='/games/:id'
                exact
                render={({ match }) => {
                  return <>{isAuthenticated ? <GameDesign gameId={match.params.id} /> : <Splash />}</>;
                }}
              />

              <Route
                path={['/games/:id/:objecttype/:objectaction/:objectid', '/games/:id/:objecttype/:objectaction/:objectid/:modifier1']}
                exact
                render={({ match, location }) => {
                  let queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
                  console.log(queryString);
                  return (
                    <>
                      {isAuthenticated ? (
                        <GameDesign
                          gameId={match.params.id}
                          objecttype={match.params.objecttype}
                          objectaction={match.params.objectaction}
                          objectid={match.params.objectid}
                          modifier1={match.params.modifier1}
                          returnTo={queryString.returnTo}
                        />
                      ) : (
                        <Splash />
                      )}
                    </>
                  );
                }}
              />

              <Route path='/privacy' exact>
                {<Privacy />}
              </Route>

              <Route path='/' exact>
                {isAuthenticated ? <GameList /> : <Splash />}
              </Route>
              <Route>
                <>No Route match</>
              </Route>
            </Switch>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Container>
        <Footer />
      </Router>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};
export default connect(mapStateToProps, null)(G4m);
