import React from 'react';
import history from "../../history";
import {connect} from "react-redux";
import {getLatestPublishedVersion, getLiveSpecVersion} from "../../utils/utils";
import Button from "react-bootstrap/Button";


const PublishGame = ({gameVersions, game, displayAs, dispatch}) => {
    const newItemHandler = () => {
        history.push(`/games/${game.id}/gameVersions/create/0`);
    }

    const isDirty = (gameVersions) => {
        // a game is dirty if there is no published version, or the updated at of the published version
        // is less than the updated at of the live spec version
        let liveSpec = getLiveSpecVersion(gameVersions);
        let latestPublished = getLatestPublishedVersion(gameVersions);
        //console.log(liveSpec?liveSpec.version:null, latestPublished?latestPublished.version:null);
        //console.log(liveSpec?liveSpec.updatedAt:null, latestPublished?latestPublished.updatedAt:null);

        if (latestPublished === null
            || liveSpec === null
            || latestPublished.updatedAt.toString() < liveSpec.updatedAt.toString()) {
            return true;
        }
        return false;
    }
    const publishControl = <Button variant="success" onClick={newItemHandler} className="mb-3 btn-block"> <i className="fas fa-upload"></i> Publish</Button>
    //const noPublishControl = <Button variant="light"  size="sm"> <i className="fas fa-plus"></i> no changes</Button>
    const noPublishControl = <></>

    return <>{ isDirty(gameVersions) ?
        publishControl :
        noPublishControl}</>
}

export default connect(null,null)(PublishGame);
