import React, { useState }  from 'react';
import { Popover, OverlayTrigger, Modal } from "react-bootstrap"
import { hasProperty, getProperty } from 'dot-prop';

import { formatDate, getTotalCount, extractData } from '../../utils/utils';


const QuestionsSummaryWidget = ({ publishedGameVersion, analytics }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ question: null, answers: [] });



  const renderResponse = (question, answer) => {
    if (Array.isArray(answer)) {
      return answer.map(
        (code) => question.options.find(o => o.code === code).txt,
      ).join(', ');
    } else {
      return answer;
    }
  }

  return (
    <>
      <h5>Questions</h5>
      <ul className="list-group mt-3">
        {publishedGameVersion.game.spec.resources.questions.map(q => {
          return (
            <li
              key={q.id}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              {q.title}
              <small className="font-sm text-right">
                Answers:
                {' '}
                <a
                  onClick={() => {
                    setModalOpen(true);
                    setModalData({
                      answers: extractData(analytics, `has.answered.question.${q.id}`),
                      question: q,
                    });
                  }}
                >
                  <strong>{getTotalCount(analytics, `has.answered.question.${q.id}`)}</strong>
                </a>

                {hasProperty(q, 'correctAnswer') && (
                  <>
                    {' / '}
                    Correct:
                    {' '}
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      onToggle={(show) => console.log(show)}
                      placement="right"
                      overlay={
                        <Popover content>
                          <ul className="list-group list-group-flush ">
                            {extractData(analytics, `has.passed.question.${q.id}`).length === 0 && (
                              <li
                                className="list-group-item"
                              >
                                -
                              </li>
                            )}
                            {extractData(analytics, `has.passed.question.${q.id}`).map(userState => {
                              return (
                                <li
                                  key={userState.userId}
                                  className="list-group-item"
                                >
                                  {(userState.avatar && userState.avatar.name) || '-'}

                                  <small className="d-block text-muted">
                                    { formatDate(getProperty(userState, `has.answered.question.${q.id}.when`))}
                                  </small>
                                </li>
                              );
                            })}
                          </ul>
                        </Popover>
                      }
                    >
                      <a>
                        <strong>{getTotalCount(analytics, `has.passed.question.${q.id}`)}</strong>
                      </a>
                    </OverlayTrigger>
                  </>
                )}
              </small>
            </li>
          )
        })}

        {/* Show Confirmations together with Questions */}
        {publishedGameVersion.game.spec.resources.confirmations.map(c => {
          return (
            <li
              key={c.id}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              {c.title}
              <small className="font-sm text-right">
                {'Accepted: '}
                <strong>{getTotalCount(analytics, `has.approved.confirmation.${c.id}`)}</strong>
                {' / Rejected: '}
                <strong>{getTotalCount(analytics, `has.rejected.confirmation.${c.id}`)}</strong>
                )}
              </small>
            </li>
          )
        })}
      </ul>

      <Modal
        size="lg"
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        className="analytics"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {modalData && modalData.question && modalData.question.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5>Answers:</h5>

          {modalData && modalData.question && modalData.question.id && (
            <table className="table table-bordered">
              <tr>
                <th>Player</th>
                <th>Answer</th>
                <th>When</th>
              </tr>
              {extractData(modalData.answers, `has.answered.question`).map(a => (
                <tr>
                  <td>
                    {(a.avatar && a.avatar.name) || '-'}
                  </td>
                  <td>
                    {renderResponse(modalData.question, getProperty(a, `has.answered.question.${modalData.question.id}.response`))}
                  </td>
                  <td>
                    {}
                    {formatDate(getProperty(a, `has.answered.question.${modalData.question.id}.when`))}
                  </td>
                </tr>
              ))}
            </table>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuestionsSummaryWidget;
