import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import React from "react";

const FormButtons = ({validationGeneralError, handleCancel, handleDelete, objectId, saveButtonText}) => {
    return (
        <>
          <hr />
            {validationGeneralError && <span className="generalFormError">{validationGeneralError}</span>}

            <Row className={'mt-4 px-3'}>
                <Col>
                    <Row className={'justify-content-start'}>
                        {
                            handleDelete &&
                                <Button variant="danger" type="button" onClick={() => handleDelete(objectId)}>
                                    <i className="fas fa-trash"></i> Delete
                                </Button>
                        }

                    </Row>
                </Col>
                <Col>
                    <Row className={'justify-content-end'}>
                            <Button variant="secondary" type="button" className={"mr-2"} onClick={handleCancel}>
                                <i className="fas fa-window-close"></i> Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                <i className="fas fa-save"></i> {saveButtonText?saveButtonText:'Save'}
                            </Button>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormButtons;
