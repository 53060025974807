import React from 'react';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {bconfirm} from "../utils/ConfirmAlert";


const FieldArrayButtons = ({rowName, fields, index}) => {

    return (
       <>
           <Button style={{borderWidth:'0px'}} className={"mr-1"} variant="outline-danger"
                   onClick={async () => {
                       const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
                       if (result) {
                           fields.remove(index)
                       }
                   }}  title={`Remove ${rowName}`}
           ><i className="fa fa-times"></i><span className={"controlLabel sr-only"}> {`Remove ${rowName}`}</span></Button>
           <Button style={{borderWidth:'0px'}} className={"mr-1"} variant="outline-secondary"
                   onClick={() => {if (index>0) return fields.move(index, index-1)}}  title={`Move ${rowName} up`}
           ><i className="fa fa-caret-up"></i><span className={"controlLabel sr-only"}> {`Move ${rowName} up`}</span></Button>
           <Button style={{borderWidth:'0px'}} className={"mr-1"} variant="outline-secondary"
                   onClick={() => {if (index<fields.length-1) return fields.move(index, index+1)}}  title={`Move ${rowName} down`}
           ><i className="fa fa-caret-down"></i><span className={"controlLabel sr-only"}> {`Move ${rowName} down`}</span></Button>
       </>
    );

};


export default FieldArrayButtons;