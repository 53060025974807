//import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchScene, editScene, createScene } from '../../actions/scenesActions';

import SceneForm from '../forms/SceneForm';
import history from '../../history';
import GoogleMapLocationForm from '../gmap/GoogleMapLocationForm';
import Col from 'react-bootstrap/Col';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import Button from 'react-bootstrap/Button';
import CollapseItems from '../widgets/CollapseItems';
import RuleList from '../rules/RuleList';
import LocationList from '../locations/LocationList';
import { shortenText } from '../../utils/utils';
import MarkerMap from '../gmap/MarkerMap';
import { ICON_URL_LOCATION } from '../gmap/iconUrls';

const SceneShow = ({ scene, game, locations, rules }) => {
  if (!scene || !game) {
    return <>Loading...</>;
  }

  const markersData = [];
  const locationObject = scene;
  locationObject.onlyRadius = true;
  locationObject.typeLabel = 'Scene center';
  locationObject.radiusColor = '#d7fffc';
  markersData.push(locationObject); // add scene

  // add all locations of scene
  locations.map((markerData) => {
    if (markerData.sceneId === scene.id) {
      console.log(markerData);
      const locationObject = markerData;
      locationObject.onlyRadius = true;
      locationObject.iconUrl = ICON_URL_LOCATION;
      locationObject.typeLabel = 'Location';
      locationObject.radiusColor = '#520040';

      markersData.push(locationObject);
    }
  });

  return (
    <div className=''>
      <h3>
        {scene.name}
        <LinkContainer to={`/games/${game.id}/scenes/edit/${scene.id}?returnTo=objectShow`}>
          <Button className={'ml-1 float-right'}>
            <i className='fas fa-cog'></i> Settings
          </Button>
        </LinkContainer>
      </h3>
      <p>{shortenText(scene.description, 400, 'No description')}</p>

      <RuleList game={game} scene={scene} rules={rules} displayAs={'collapse'} />
      <LocationList game={game} scene={scene} locations={locations} displayAs={'collapse'} />
      {/*<GoogleMapLocationForm  lat={scene.lat} radius={scene.radius} lng={scene.lng}*/}
      {/*                        onMarkerDrag={false} />*/}

      <MarkerMap markersData={markersData}></MarkerMap>
    </div>
  );
};

export default SceneShow;
