import DOMPurify from 'dompurify';
import { getProperty } from 'dot-prop';
import moment from 'moment';

export const getUserGreetingName = (user) => {
  return user.name;
};

export const somethingElse = (user) => {
  console.log(user);
  return user.name;
};

export const extractData = (analytics, path) => {
  if (!Array.isArray(analytics)) {
    return ['-'];
  }
  return analytics.filter((state) => getProperty(state, path));
};

export const getTotalCount = (analytics, path) => {
  if (!Array.isArray(analytics)) {
    return '-';
  }
  return extractData(analytics, path).length;
};

export const getDatePart = (dateTime) => {
  if (!dateTime) {
    return '';
  }
  return dateTime.substring(0, 10);
  //return dateTime;
};
/**
 * makes sure lat, lng and radius properties of object are set
 * @param object
 * @param onlyIfSet
 */
export const validateCoords = (object, forceValue) => {
  if (!object) {
    object = {};
  }
  if (forceValue) {
    // Force a value on lat, lng and radius.
    // This should be done on creation of a game or scene
    object.lat = parseFloat(object.lat);
    object.lng = parseFloat(object.lng);
    object.radius = parseInt(object.radius);
  }

  // if there is a value on lat, lng and radius, and this value is invalid, set it
  if (object.hasOwnProperty('lat') && (!object.lat || isNaN(object.lat))) {
    object.lat = 0;
  }
  if (object.hasOwnProperty('lng') && (!object.lng || isNaN(object.lng))) {
    object.lng = 0;
  }
  if (object.hasOwnProperty('radius') && (!object.radius || isNaN(object.radius))) {
    object.radius = 100;
  }
};

export const shortLabelForConversationStatement = (conversationStatement) => {
  return shortenText(conversationStatement.description, 50, '-');
  // const MAX_LENGTH = 50;
  // if (!conversationStatement.description) {
  //     return '-';
  // }
  // const cleanText = DOMPurify.sanitize(conversationStatement.description, {ALLOWED_TAGS: []}); // no allowed tags. So everything is stripped out
  // if (cleanText && cleanText.length < MAX_LENGTH) {
  //     return cleanText;
  // }
  // return cleanText.substring(0,MAX_LENGTH)+'...';
};

export const shortenText = (text, maxLength, defaultIfEmpty) => {
  if (typeof maxLength === 'undefined') {
    maxLength = 50;
  }
  if (typeof defaultIfEmpty === 'undefined') {
    defaultIfEmpty = '-';
  }
  if (!text || text === '') {
    return defaultIfEmpty;
  }
  let cleanText = DOMPurify.sanitize(text, { ALLOWED_TAGS: [] }); // no allowed tags. So everything is stripped out
  if (cleanText === '') {
    cleanText = defaultIfEmpty;
  }
  if (cleanText && cleanText.length < maxLength) {
    return cleanText;
  }
  return cleanText.substring(0, maxLength) + '...';
};

export const getLiveSpecVersion = (gameVersions) => {
  if (!gameVersions) {
    return null;
  }
  let count;
  for (count = 0; count < gameVersions.length; count++) {
    if (gameVersions[count].isLiveSpec) return gameVersions[count];
  }
  return null;
};
export const getLatestPublishedVersion = (gameVersions) => {
  // find the version with the highest "version" property
  let count;
  let largest = null;
  for (count = 1; count < gameVersions.length; count++) {
    if (gameVersions[count].isLiveSpec !== true) {
      if (largest === null || gameVersions[count].version > largest.version) {
        largest = gameVersions[count];
      }
    }
  }
  return largest;
};

export const formatDate = (date, format = 'lll') => {
  if (!date) {
    return '-';
  }

  let dt = typeof date === 'string' ? moment(date) : date;
  return dt.format(format);
};
