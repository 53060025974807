import { Field } from 'redux-form';
import renderSelect from './renderers/renderSelect';
import { connect } from 'react-redux';
import React from 'react';
//import {ARCHETYPE_FORM_NAME as FORM_NAME} from "./formTypes";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../../i18n';

import renderCheckbox from './renderers/renderCheckbox';

import {
  INSTANCE_PROP_IMAGE,
  INSTANCE_PROP_NUMBER,
  INSTANCE_PROP_TEXTMULTI,
  INSTANCE_PROP_TEXTSINGLE,
  INSTANCE_PROP_SOUND,
  INSTANCE_PROP_VIDEO,
  INSTANCE_PROP_EMAIL,
  INSTANCE_PROP_MEDIA,
} from '../archetypes/archetypeConstants';
import renderInput from './renderers/renderInput';

import { useTranslation } from 'react-i18next';
import { bconfirm } from '../utils/ConfirmAlert';
import FieldArrayButtons from './FieldArrayButtons';
import { Row } from 'react-bootstrap';

//const selector = formValueSelector(FORM_NAME);

let PayloadSpec = ({ payloadSpec, index, fields }) => {
  const { t, i18n } = useTranslation();
  const field = fields.get(index);
  const label = <Field name={`${payloadSpec}.label`} label='Property Label' asCol={true} component={renderInput} />;

  // switch (type) {
  //     case INSTANCE_PROP_IMAGE:
  //         break;
  //     case INSTANCE_PROP_NUMBER:
  //         break;
  //     case INSTANCE_PROP_TEXTMULTI:
  //         break;
  //     case INSTANCE_PROP_TEXTSINGLE:
  //         break;
  // }
  return (
    <>
      <Row noGutters>
        <Col className='lg-col-10 md-col-12 border-right'>
          <Row>
            <Field asCol={true} name={`${payloadSpec}.type`} component={renderSelect} label='Property Type'>
              <option value={''}>{t('-- Select type --')}</option>
              <option value={INSTANCE_PROP_TEXTSINGLE}>{t(INSTANCE_PROP_TEXTSINGLE)}</option>
              <option value={INSTANCE_PROP_EMAIL}>{t(INSTANCE_PROP_EMAIL)}</option>
              <option value={INSTANCE_PROP_NUMBER}>{t(INSTANCE_PROP_NUMBER)}</option>
              <option value={INSTANCE_PROP_TEXTMULTI}>{t(INSTANCE_PROP_TEXTMULTI)}</option>
              <option value={INSTANCE_PROP_IMAGE}>{t(INSTANCE_PROP_IMAGE)}</option>
              <option value={INSTANCE_PROP_SOUND}>{t(INSTANCE_PROP_SOUND)}</option>
              <option value={INSTANCE_PROP_VIDEO}>{t(INSTANCE_PROP_VIDEO)}</option>
              <option value={INSTANCE_PROP_MEDIA}>{t(INSTANCE_PROP_MEDIA)}</option>
            </Field>
            <Field name={`${payloadSpec}.label`} label='Property Label' asCol={true} component={renderInput} />
            <Field asCol={true} placementFix component={renderCheckbox} name={`${payloadSpec}.hideLabel`} type='checkbox' label='Label Hidden' />
          </Row>
          <Row>
            <Field asCol={true} component={renderCheckbox} name={`${payloadSpec}.required`} type='checkbox' label='Required' />
            <Field asCol={true} component={renderCheckbox} name={`${payloadSpec}.hidden`} type='checkbox' label='Hidden' />
            <Field
              asCol={true}
              disabled={![INSTANCE_PROP_TEXTSINGLE, INSTANCE_PROP_EMAIL, INSTANCE_PROP_NUMBER].includes(field.type)}
              component={renderCheckbox}
              name={`${payloadSpec}.objectTitle`}
              type='checkbox'
              label='Object Title'
            />
            {/* <Col>
            </Col>
            <Col>
            </Col>
            <Col>
            </Col> */}
          </Row>
        </Col>
        <Col lg={'auto'} className='lg-col-2 md-col-12 text-center m-auto'>
          <FieldArrayButtons rowName={'Property'} fields={fields} index={index} />
        </Col>
      </Row>
    </>
  );
};
PayloadSpec = connect((state, props) => ({
  //type: selector(state, `${props.instanceProp}.type`),
}))(PayloadSpec);

export default PayloadSpec;
