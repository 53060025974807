import React from "react";
import renderError from "./renderError";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

// const renderBooleanRadio = ({input, label, meta,  ...rest}) => {

const renderBooleanRadio = ({input, label, meta, disabled}) => {
    const className = `${meta.error && meta.touched ? 'invalid':''}`;
    const disabledProp = {};
    if (disabled) {
        disabledProp.disabled = 'disabled'; //
    }
    return (
        <>
            <Form.Check {...input}  id={input.name+input.value} {...disabledProp} inline label={label} type={"radio"} className={className} />
            {renderError(meta)}
        </>
    );
}
export default renderBooleanRadio;



