import React from 'react';
import SceneList from '../scenes/SceneList';
import MilestoneList from '../milestones/MilestoneList';
import MissionList from '../missions/MissionList';
import GameVersionList from '../gameVersions/GameVersionList';
import InitlaStateMenuItems from '../initialState/InitlaStateMenuItems';

import ItemList from '../items/ItemList';
import CharacterList from '../characters/CharacterList';
import AttributeList from '../attributes/AttributeList';
import ArchetypeList from '../archetypes/ArchetypeList';
import OfferList from '../offers/OfferList';
import InteractionList from '../interactions/InteractionList';
import { getLiveSpecVersion, getLatestPublishedVersion } from './../../utils/utils';
import PublishGame from '../gameVersions/PublishGame';

const MainSidebar = ({ game, scenes, interactions, milestones, missions, gameVersions, items, characters, archetypes, attributes, offers }) => {
  if (!game) {
    return <>Loading...</>;
  }

  return (
    <>
      <PublishGame game={game} gameVersions={gameVersions}></PublishGame>
      <h4 className='mt-3 menu-subheading'>{'Game Plot'}</h4>
      <div className='menu-subsection'>
        <div className='gameelementscontainer row'>
          <SceneList game={game} scenes={scenes} displayAs={'collapse'} />
          <MilestoneList game={game} milestones={milestones} displayAs={'collapse'} />
          <MissionList game={game} missions={missions} displayAs={'collapse'} />
        </div>
      </div>
      <h4 className='mt-3 menu-subheading'>{'Game Elements'}</h4>
      <div className='menu-subsection'>
        <InteractionList game={game} interactions={interactions} displayAs={'collapse'} />
        <div className='gameelementscontainer row'>
          <ItemList game={game} items={items} displayAs={'collapse'} />
          <CharacterList game={game} characters={characters} displayAs={'collapse'} />
          <AttributeList game={game} attributes={attributes} displayAs={'collapse'} />
          <ArchetypeList game={game} archetypes={archetypes} displayAs={'collapse'} />
          <OfferList game={game} offers={offers} displayAs={'collapse'} />
          {/*<GameVersionList game={game} gameVersions={gameVersions} displayAs={'collapse'}/>*/}
        </div>
      </div>
      <h4 className='mt-3 menu-subheading'>{'Game World'}</h4>
      <div className='menu-subsection'>
        <div className='gameelementscontainer row'>
          <InitlaStateMenuItems game={game} displayAs={'collapse'} />
        </div>
      </div>
    </>
  );
};
export default MainSidebar;
