import React from 'react';

import { change, Field, formValueSelector, reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import { LOCATION_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import ImageChoose from './ImageChoose';
import GoogleMapLocationForm from '../gmap/GoogleMapLocationForm';
import { ICON_URL_LOCATION } from '../gmap/iconUrls';
import FormButtons from './FormButtons';
import { useTranslation } from 'react-i18next';
import renderCheckbox2 from './renderers/renderCheckbox2';
import ConditionForm2 from './ConditionForm2';
import { asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate } from '../utils/validationUtils';
const selector = formValueSelector(FORM_NAME);

const LocationForm = ({ error, dispatch, handleSubmit, onSubmit, handleCancel, scene, game, initialValues, logoId, handleDelete }) => {
  const { t, i18n } = useTranslation();

  const onMarkerDrag = (lat, lng) => {
    dispatch(change(FORM_NAME, 'lat', lat));
    dispatch(change(FORM_NAME, 'lng', lng));
  };
  // βάσει https://github.com/redux-form/redux-form/issues/3701
  // για να μην κάνει propagate το event προς τα πάνω
  // γιατί παίζει να είναι react nested (όχι DOM nested) σε άλλη φόρμα
  // και σε αυτή την περίπτωση κάνει submit και την container form
  const formHandleSubmit = (form) => {
    return function handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(submit(form));
    };
  };
  // έτσι κι αλλιώς, η onSubmit που έρχετε από τα props θα κληθεί αυτόματα
  /*
    const myOnSubmit = (formValues) => {
        console.log('SKATA ----------- myOnSubmit LocationForm ', formValues);
        onSubmit(formValues);
    };
*/
  //                    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
  return (
    <Container fluid>
      <Form noValidate onSubmit={formHandleSubmit(FORM_NAME)}>
        <Row>
          <Col lg={5}>
            <Field name='sceneId' component='input' type='hidden' />
            <Field name='gameId' component='input' type='hidden' />
            <Field name='name' label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />
            <Field name='title' label='Title' asCol={false} component={renderInput} />
            <Form.Row className='mb-4'>
              <Field
                asCol={true}
                cols={8}
                name='logoId'
                label='Map Icon'
                logoIdToShow={logoId}
                imageWidth={300}
                handleRemoveMedia={() => dispatch(change(FORM_NAME, 'logoId', null))}
                gameId={initialValues && initialValues.gameId}
                handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'logoId', newId))}
                component={ImageChoose}
              />
              <Col></Col>
              <Field asCol={true} cols={4} width={40} name='iconSize' label='Icon Size' type='number' step='2' component={renderInput} />
            </Form.Row>
            <Form.Row>
              <Field asCol={true} name='lat' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Latitude' type='number' step='0.001' component={renderInput} />
              <Field asCol={true} name='lng' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Longitude' type='number' step='0.001' component={renderInput} />
              <Field asCol={true} name='radius' label='Radius (m)' type='number' step='10' component={renderInput} />
            </Form.Row>
          </Col>
          <Col lg={7}>
            <GoogleMapLocationForm
              formName={FORM_NAME}
              lat={initialValues.lat}
              noRadius={false}
              lng={initialValues.lng}
              onMarkerDrag={onMarkerDrag}
              inconUrl={ICON_URL_LOCATION}
              style={{ minHeight: 500 }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field className={'mt-5'} inline={true} component={renderCheckbox2} name={`visible`} type='checkbox' label={`Visible`} props={{ containerControlClass: 'mt-3' }} />

            <fieldset className='border p-2 mb-3'>
              <legend className='w-auto legend-small'>Percieved only if</legend>
              <ConditionForm2 dispatch={dispatch} scene={null} game={game} baseFieldName={'if'} formName={FORM_NAME}></ConditionForm2>
            </fieldset>
          </Col>
        </Row>
        <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
      </Form>
    </Container>
  );
};

const validate = (formValues) => {
  const errors = {};
  g4mFormValidate(errors, formValues, ['title', 'if']); // name is validated asynchronously
  g4mFormTotalFormValidate(errors);

  //console.log("Validation errors of rule form", errors);
  return errors;
};

// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
//  })(GameForm);

//
// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
// })(LocationForm);

export default connect((state, ownProps) => {
  return {
    logoId: selector(state, 'logoId'),
    //visible:selector(state, 'visible'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
    asyncValidate: asyncValidateForm,
    asyncBlurFields: ['name'],
  })(LocationForm)
);
