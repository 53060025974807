import React from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { getUserGreetingName } from '../../utils/utils';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import { getThumbUrlById } from '../utils/mediaUtils';
import { Container, NavItem } from 'react-bootstrap';

const Header = ({ isAuthenticated, logoutUser, user, activeGameId, game }) => {
  return (
    <header className=''>
      <Navbar bg='dark' expand='md' variant='dark'>
        <LinkContainer to='/' className={'mr-5 bg-rollick-primary'}>
          <Navbar.Brand href='/' className='pr-4 pl-4'>
            <img
              src={`${process.env.PUBLIC_URL}/resources/images/rollick_icon_transparrent.svg`}
              width='30'
              height='30'
              alt='Rollick'
              className='mr-1'
            />
            Rollick
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            {isAuthenticated && activeGameId && activeGameId !== 'new' && (
              <>
                {game && game.logoId && (
                  <Navbar.Brand href='#home'>
                    <img
                      src={getThumbUrlById(game.logoId, true)}
                      width='30'
                      height='30'
                      className='d-inline-block align-top'
                      alt={`${game.title}'s logo`}
                      title={`${game.title}`}
                    />
                  </Navbar.Brand>
                )}
                <LinkContainer
                  isActive={(a) => a && a.path === `/games/${activeGameId}`}
                  to={`/games/${activeGameId}`}
                  exact
                >
                  <Nav.Link>Design</Nav.Link>
                </LinkContainer>
                <LinkContainer
                  exact
                  isActive={(a) =>
                    a &&
                    a.path &&
                    a.path.includes([`/games/edit/${activeGameId}`])
                  }
                  to={`/games/edit/${activeGameId}`}
                >
                  <Nav.Link>Configure</Nav.Link>
                </LinkContainer>
                <LinkContainer
                  exact
                  isActive={(a) =>
                    a && a.path && a.path.includes('/active-games')
                  }
                  to={`/active-games/${activeGameId}`}
                >
                  <Nav.Link>Manage</Nav.Link>
                </LinkContainer>
                <LinkContainer
                  exact
                  isActive={(a) => a && a.path && a.path.includes('/analytics')}
                  to={`/analytics/${activeGameId}`}
                >
                  <Nav.Link>Analytics</Nav.Link>
                </LinkContainer>
              </>
            )}
            {!isAuthenticated && (
              <>
                <LinkContainer to='/login'>
                  <Nav.Link>Sign in</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/register'>
                  <Nav.Link>Sign up</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
          {isAuthenticated && (
            <Nav>
              <NavDropdown title={user.email} id='basic-nav-dropdown'>
                <LinkContainer to='/account'>
                  <NavDropdown.Item>Account</NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Item onClick={logoutUser}>
                  Logout{' '}
                </NavDropdown.Item>
                {/*<NavDropdown.Divider />*/}
                {/*<NavDropdown.Item href="#action/3.4">Something else link</NavDropdown.Item>*/}
              </NavDropdown>
            </Nav>
          )}
          <Navbar.Brand
            href='https://docs.rollick.tuc.gr'
            target='_new'
            className='pr-2 pl-2 bg-rollick-primary'
          >
            <img
              src={`${process.env.PUBLIC_URL}/resources/images/docs.svg`}
              width='30'
              height='30'
              alt='Rollick'
              className='mr-1'
            />
            Rollick Docs
          </Navbar.Brand>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

const mapStateToProps = (state, ownProps) => {
  // to object.values pairnei ola ta properties enos object kai ta metatrepei ta values se array
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    game: state.games[ownProps.activeGameId],
  };
};
export default connect(mapStateToProps, { logoutUser })(Header);
