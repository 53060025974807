import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';

import renderError from './renderers/renderError';
import Image from 'react-bootstrap/Image';

import Button from 'react-bootstrap/Button';
import { getThumbUrl } from '../utils/mediaUtils';
import MediaModal from '../media/MediaModal';

const ImageChoose = ({ input, label, meta, asCol, media, gameId, handleRemoveMedia, handleSetMediaId, width, imageWidth }) => {
  const [showMediaModal, setShowMediaModal] = useState(false);

  const className = `${meta.error && meta.touched ? 'invalid' : ''}`;

  const formGroupPros = {}; // for handling <Form.Group as={Col}>

  const handleShowMediaModal = () => setShowMediaModal(true);
  const handleCloseMediaModal = () => setShowMediaModal(false);

  const handleMediaChoosen = (newId) => {
    handleSetMediaId(newId);
    setShowMediaModal(false);
  };
  if (asCol) {
    formGroupPros.as = Col;
  }

  return (
    <>
      <Form.Row>
        <Col lg={'auto'}>
          <Form.Control {...input} className={className} type={'hidden'} />
        </Col>
      </Form.Row>
      <Col style={{ padding: 0 }}>
        <Form.Label>{label}</Form.Label>
        <Form.Row>
          <Col>
            <div className={`media-thumb-container`} style={{ width: width ?? '100%', height: width ?? '100%' }}>
              {<Image src={getThumbUrl(media, true, imageWidth)} style={{ width: '100%', height: '100%' }} />}
              <Button variant='primary' onClick={handleShowMediaModal} className={`edit-media-button media-button`}>
                ✎
              </Button>
              {media && (
                <Button
                  variant='danger'
                  onClick={() => {
                    handleRemoveMedia();
                  }}
                  className={`remove-media-button media-button`}>
                  ×
                </Button>
              )}
            </div>
          </Col>
        </Form.Row>
      </Col>
      {meta.error && meta.touched && (
        <Form.Row>
          <Col lg={'auto'}>{renderError(meta)}</Col>
        </Form.Row>
      )}
      <MediaModal openMediaModal={showMediaModal} handleMediaChoosen={handleMediaChoosen} closeMediaModal={handleCloseMediaModal} initialSelectedMedia={media} gameId={gameId}></MediaModal>
    </>
  );
};

//export default renderimage;

const mapStateToProps = (state, ownProps) => ({
  media: state.medias[ownProps.logoIdToShow],
});
export default connect(mapStateToProps)(ImageChoose);
