import { Field } from 'redux-form';
import React from 'react';

export const communicationActions = ['showInfo', 'showNotification', 'askQuestion', 'askQuiz', 'askConfirmation', 'startConversation', 'resumeConversation', 'assignMission', 'archiveMission'];
export const communicationActionsConversation = ['assignMission', 'archiveMission'];
export const itemPlayerActions = ['giveToPlayer', 'takeFromPlayer', 'setOnPlayer'];
export const objectPlayerActions = ['removePlayerObjects'];
export const attributePlayerActions = ['attachToPlayer', 'detatchFromPlayer'];
export const itemWorldActions = ['giveToWorld', 'takeFromWorld', 'setOnWorld'];
export const attributeWorldActions = ['attachToWorld', 'detatchFromWorld', 'returnToWorld', 'removeFromWorld'];
export const attributeArchetypeActions = ['attachToObject', 'detatchFromObject'];
export const gameActions = ['enableScene', 'disableScene', 'endGame', 'completeGame'];
export const archetypeActions = ['pickObject', 'mutateObject', 'destroyObject', 'showObject', 'pickObjectClone'];
export const conversationActions = ['sayStatement', 'exitConversation', 'completeConversation'];

//export const actionGroups = ['communicationActions', 'playerState', 'worldState', 'evolveGame'];
export const actionGroupActions = {
  communicationActions: communicationActions,
  playerState: itemPlayerActions.concat(attributePlayerActions).concat(objectPlayerActions),
  worldState: itemWorldActions.concat(attributeWorldActions),
  evolveGame: gameActions,
};

export const actionGroupActionsWithArchetypes = {
  currentArchetypeActions: archetypeActions,
  communicationActions: communicationActions,
  playerState: itemPlayerActions.concat(attributePlayerActions).concat(objectPlayerActions),
  worldState: itemWorldActions.concat(attributeWorldActions),
  evolveGame: gameActions,
};

export const actionGroupActionsWithConversations = {
  communicationActions: communicationActionsConversation,
  currentConversationActions: conversationActions,
  playerState: itemPlayerActions.concat(attributePlayerActions).concat(objectPlayerActions),
  worldState: itemWorldActions.concat(attributeWorldActions),
};

export const actionsThatDoNotNeedWhat = ['showNotification', 'endGame', 'completeGame', 'pickObject', 'pickObjectClone', 'destroyObject', 'showObject', 'exitConversation', 'completeConversation'];

/* SOS SOS SOS SOS Αυτή η λίστα υπάρχει και στο BE */
export const blockingActions = ['showInfo', 'askQuestion', 'askQuiz', 'askConfirmation', 'startConversation', 'resumeConversation', 'showObject', 'sayStatement'];
