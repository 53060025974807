//import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { setCookie, getCookie } from '../utils/cookieUtils';
import { useTranslation } from 'react-i18next';
import ArchetypeListItem from './ArchetypeListItem';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideBarMenuItem } from '../../actions/appActions';

//const isEmpty = require("is-empty");
const STATE_COOKIE_NAME = 'g4mAccStates';

const CollapseArchetypes = ({
  showCount,
  archetypes,
  heading,
  identifier,
  newItemHandler,
  newLabel,
  editItemHandler,
  editObjectHandler,
  newObjectHandler,
  editLabel,
  visitItemHandler,
  visitLabel,
  deleteItemHandler,
  deleteLabel,
  additionalClass,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector((state) => !!state.app.menu[identifier]);
  const toggleAccordion = () => {
    dispatch(toggleSideBarMenuItem(identifier));
  };

  const getItemList = () => {
    if (archetypes.length === 0) {
      return <p>No items</p>;
    }
    return archetypes.map((item) => {
      //const itemId = item.id;
      let label = (
        <span>
          {item.specialNamePrefixMarkup} {item.name}
        </span>
      );
      if (editItemHandler) {
        label = (
          <span className='clickable-collapsible-label' style={{ paddingRight: 5 }} onClick={() => editItemHandler(item.id)} title={editLabel}>
            {item.specialNamePrefixMarkup} {item.name}
          </span>
        );
      }

      return (
        <ArchetypeListItem
          newObjectHandler={newObjectHandler}
          editObjectHandler={editObjectHandler}
          key={item.id}
          heading={label}
          identifier={item.id}
          newLabel={`New ${item.name}`}
          newItemHandler={() => {}}
          showCount={true}
          additionalClass={'nested-collapse'}
        />
      );
    });
  };

  let collapsibleStateClass = 'closed';
  if (open) {
    collapsibleStateClass = 'opened';
  }
  let newItemControl = null;
  if (newItemHandler) {
    newItemControl = (
      <li title={newLabel} onClick={() => newItemHandler(identifier)}>
        <Button variant='success' style={{ minWidth: 150 }}>
          {' '}
          <i className='fas fa-plus'></i> {newLabel}
        </Button>
      </li>
    );
  }
  return (
    <div className={`g4mcollapsible col-12 ${collapsibleStateClass} ${additionalClass}`} id={identifier}>
      <div className='g4mcollapsible-heading'>
        <div aria-controls={identifier + '-collapse'} className={'font-weight-bold d-flex w-100'} onClick={toggleAccordion}>
          {heading} {showCount && `(${archetypes.length})`}
          <span className='ml-auto'>
            <i className='fas fa-angle-down showopened'></i>
            <i className='fas fa-angle-right closedicon showclosed'></i>
          </span>
        </div>
      </div>

      <Collapse in={open}>
        <ul className='g4mcollapsible-content' id={identifier + '-collapse'}>
          {newItemControl}
          {getItemList()}
        </ul>
      </Collapse>
    </div>
  );
};
CollapseArchetypes.propTypes = {
  showCount: PropTypes.bool,
};

CollapseArchetypes.defaultProps = {
  showCount: true,
};

export default CollapseArchetypes;
