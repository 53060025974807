import {toastr} from "react-redux-toastr";

export const displayError = (err, title) =>  {
    if (!err) {
        toastr.error(title);
    }
    else {
        if (err.response) {
            if (err.response.status == 404) {
                toastr.error(title, `${err.response.status} ${err.response.statusText}`);
            }
            else {
                toastr.error(title, `${err.response.status} ${err.response.statusText} ${err.response.data}`);
            }
        }
        else {
            toastr.error('No response from server', title);
        }

    }

};

export const displaySuccess = (title, text) =>  {
    toastr.success(title, text);
};