import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import SocialLoginButtons from './SocialLoginButtons';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import Form from 'react-bootstrap/Form';
import MainContent from '../layouts/MainContent';
import Button from 'react-bootstrap/Button';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
    };
  }
  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    }
  }

  /*
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/"); // push user to dashboard when they login
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
*/
  /* Antikathista to componentWillReceiveProps https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#updating-state-based-on-props */
  static getDerivedStateFromProps(nextProps, state) {
    console.log('in Login getDerivedStateFromProps, nextProps is ');
    console.log(nextProps);
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push('/'); // push user to dashboard when they login
    }
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    //console.log('GOT USER DATA TO SUBMIT');
    //console.log(userData);
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };
  render() {
    const { errors } = this.state;
    const PENDING_CONFIRMATION = 'PENDING_CONFIRMATION';
    return (
      <MainContent isAuthView header='' hasSideBar={false}>
        <div className='row justify-content-md-center logo-container'>
          <div className='col-md-3 mb-3 text-center'>
            <img width='400' src={`${process.env.PUBLIC_URL}/resources/images/rollick_icon_transparrent.svg`} className='' alt='Rollick logo' />
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='card col-lg-4 col-md-6 col-sm-8'>
            <article className='card-body'>
              <LinkContainer to={`/register`}>
                <Button variant='outline-primary' className='float-right'>
                  Sign up
                </Button>
              </LinkContainer>

              <h4 className='card-title mb-4 mt-1'>Sign in</h4>

              <SocialLoginButtons />
              <hr />
              <p className={'red-text'}>{errors.generalError}</p>
              <Form noValidate onSubmit={this.onSubmit}>
                <Form.Group controlId='email'>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    onChange={this.onChange}
                    value={this.state.email}
                    error={errors.email}
                    type='email'
                    className={errors.email || errors.emailnotfound ? 'invalid' : ''}
                    placeholder='Enter email'
                  />
                  <Form.Text className='text-muted'>
                    <span className={'red-text'}>
                      {errors.email}
                      {errors.emailnotfound}
                    </span>
                  </Form.Text>
                  <Form.Text className='text-muted'>We'll never share your email with anyone else.</Form.Text>
                </Form.Group>
                <Form.Group controlId='password'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={this.onChange}
                    value={this.state.password}
                    error={errors.password}
                    type='password'
                    className={errors.password || errors.passwordincorrect ? 'invalid' : ''}
                    placeholder='*****'
                  />
                  <Form.Text className='text-muted'>
                    <span className={'red-text'}>
                      {errors.password}
                      {errors.passwordincorrect}
                    </span>
                    {errors.errorCode === PENDING_CONFIRMATION && (
                      <>
                        <br />
                        <LinkContainer to={`/resend`}>
                          <a className='small'> Resend confirmation email?</a>
                        </LinkContainer>
                      </>
                    )}
                  </Form.Text>
                </Form.Group>

                <div className='row justify-content-between px-3'>
                  <div>
                    <LinkContainer to={`/forgot`}>
                      <a> Forgot password?</a>
                    </LinkContainer>
                  </div>
                  <div className='form-group'>
                    <button type='submit' className='btn btn-primary btn-block px-4'>
                      {' '}
                      Login
                    </button>
                  </div>
                </div>
              </Form>
            </article>
          </div>
        </div>
      </MainContent>
    );
  }
}
//Login.defaultProps = {
//  errors: {}
//};
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(Login);
