import React, {useState} from 'react';
import CollapseItems from "../widgets/CollapseItems";
import {bconfirm} from "../utils/ConfirmAlert";
import {deleteRule} from "../../actions/rulesActions";
import RuleEdit from './RuleEdit';
import ModalEdit from "../utils/ModalEdit";
import {connect} from "react-redux";
import history from "../../history";

const RuleList = ({rules, game, scene, displayAs, dispatch, ruleEditMode}) => {
    const [openEditRuleModal, setOpenEditRuleModal] = useState(false);
    const [selectedRuleToEdit, setSelectedRuleToEdit] = useState({});
    const closeEditRuleModal = () => {
        console.log('RuleList closeEditRuleModal');

        setOpenEditRuleModal(false);
    };
    const cancelEditRuleModal = () => {
        setOpenEditRuleModal(false);
    };
    const newItemHandlerModal = () => {
        setSelectedRuleToEdit(null);
        setOpenEditRuleModal(true);
        //history.push(`/games/${game.id}/rules/create/0/${scene.id}`);
    }
    const editItemHandlerModal = (id) => {
        const rule = rules.find(element => element.id === id);
        setSelectedRuleToEdit(rule);
        setOpenEditRuleModal(true);
        //history.push(`/games/${game.id}/rules/edit/${id}`);
    }

    const newItemHandlerFullPage = () => {
        history.push(`/games/${game.id}/rules/create/0/${scene.id}`);
    }
    const editItemHandlerFullPage = (id) => {
        history.push(`/games/${game.id}/rules/edit/${id}`);
    }

    const itemList = [];
    for (const item of rules) {
        if (scene && item.sceneId && item.sceneId.toString() === scene.id.toString()) {
            itemList.push({id: item.id, name: item.name});
        }
    }

    let newItemHandler = newItemHandlerFullPage;
    let editItemHandler = editItemHandlerFullPage;
    if (ruleEditMode === 'modal') {
        newItemHandler = newItemHandlerModal;
        editItemHandler = editItemHandlerModal;
    }


    if (displayAs === 'collapse') {
        return (
            <>
                <CollapseItems identifier="rulelist" heading="Scene Rules" items={itemList}
                               newItemHandler={newItemHandler}
                               newLabel="New"
                               editItemHandler={editItemHandler}
                               deleteItemHandler={null}
                               editLabel="Settings"
                               deleteLabel="Delete"
                               additionalClass="sideBar"
                />
                <ModalEdit openEditModal={openEditRuleModal} closeEditModal={closeEditRuleModal}
                           heading={"Edit or create rule"}
                portal="rule">
                    <RuleEdit game={game} scene={scene} rule={selectedRuleToEdit}
                              onSave={closeEditRuleModal}
                              onCancel={cancelEditRuleModal}
                    />
                </ModalEdit>
            </>
        );
    }
    return <>No displayAs</>
}

export default connect(null,null)(RuleList);