import {Field, formValueSelector} from "redux-form";
//import renderBooleanRadio from "./renderers/renderBooleanRadio";
import Prompt from "./Prompt";
import McOptions from "./McOptions";
import {connect} from "react-redux";
import React from "react";
import {INTERACTION_FORM_NAME as FORM_NAME} from "./formTypes";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
//import Button from "react-bootstrap/Button";
import renderCheckbox from "./renderers/renderCheckbox";

import {QTYPE_OPEN,QTYPE_MC,
    DTYPE_NUMBER,DTYPE_STEXT,DTYPE_MTEXT} from "../interactions/interactionConstants";
import renderInput from "./renderers/renderInput";
import renderSelect from "./renderers/renderSelect";
// import {
//     INTER_ANSWER_QUESTION,
//     INTER_CONFIRM,
//     INTER_CONVERSATION,
//     INTER_INSPECT_INFO,
//     INTER_QUIZ
// } from "../interactions/interactionTypes";
import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";



const selector = formValueSelector(FORM_NAME);

let Question = ({ question, index, fields, qType, gameId, hasCorrectAnswer,dType, singleQuestion, allowMultipleAnswers }) => {
    const { t, i18n } = useTranslation();


    const prompt = <Prompt fieldNameBase={question} gameId={gameId} additionalRTEClass="short-rte" textLabel={'Question'}></Prompt>;

    const questionComponent = (
        <>

            {
                // // only if we have multiple question
                // !singleQuestion &&
                // <Form.Row>
                //     <Col lg={"auto"}>
                //         <Button  className={"mr-1"} variant="outline-danger"
                //                 onClick={() => fields.remove(index)}  title="Remove Question"
                //         ><i className="fa fa-times"></i><span className={"controlLabel sr-only"}> Remove Question</span></Button>
                //     </Col>
                // </Form.Row>
            }


            <Form.Row>
                <Field name={`${question}.title`} label="Title" asCol={true} component={renderInput} />
            </Form.Row>
            <Form.Row>
                {prompt}
            </Form.Row>

            <Form.Row>
                <Col>

                    <Field name={`${question}.qType`} component={renderSelect} label="Question type">
                        <option key={'empty'}></option>
                        <option key={QTYPE_OPEN} value={QTYPE_OPEN}>{t('QTYPE_'+QTYPE_OPEN)}</option>
                        <option key={QTYPE_MC} value={QTYPE_MC}>{t('QTYPE_'+QTYPE_MC)}</option>
                    </Field>
                </Col>


                {
                    qType === QTYPE_OPEN &&
                    <Col>
                        <Field name={`${question}.dType`} component={renderSelect} label="Answer type">
                            <option key={'empty'}></option>
                            <option key={DTYPE_NUMBER} value={DTYPE_NUMBER}>{t('DTYPE_'+DTYPE_NUMBER)}</option>
                            <option key={DTYPE_STEXT} value={DTYPE_STEXT}>{t('DTYPE_'+DTYPE_STEXT)}</option>
                            <option key={DTYPE_MTEXT} value={DTYPE_MTEXT}>{t('DTYPE_'+DTYPE_MTEXT)}</option>
                        </Field>
                    </Col>
                }

                {
                    qType === QTYPE_MC &&
                    <Col>
                        <Field placementFix component={renderCheckbox} name={`${question}.allowMultipleAnswers`} type="checkbox" label="Allow multiple answers" />
                    </Col>
                }

                <Col>
                    <Field placementFix  component={renderCheckbox} name={`${question}.hasCorrectAnswer`} type="checkbox" label="There is a correct answer" />
                </Col>
            </Form.Row>
            {
                // attempts
                // hint
                // reTryIfFailed
                hasCorrectAnswer &&
                <Form.Row>
                    <Col>
                        <Field  name={`${question}.attempts`} type={'number'} step={1} min={1} label="Attempts" tooltip="Number of Attempts to answer correctly" component={renderInput} />
                    </Col>
                    <Col>
                        <Field  name={`${question}.hint`} type={'text'} label="Hint" tooltip="A text message to be displayed as a hint to the player at the first wrong answer" component={renderInput} />
                    </Col>
                    {singleQuestion &&
                    <Col>
                        <Field placementFix name={`${question}.reTryIfFailed`}
                               tooltip="Allow the player to see the failed question in the ToDo list"
                               label="Keep as pending if answered wrongly" component={renderCheckbox}/>
                    </Col>
                    }
                </Form.Row>
            }
            {
                (hasCorrectAnswer && qType !== QTYPE_MC) &&
                <Form.Row>
                    <Col>
                        <Field name={`${question}.correctAnswer`} type={dType===DTYPE_NUMBER?'number':'text'} label="Correct answer" component={renderInput} />
                    </Col>
                </Form.Row>
            }

            {
                (qType === QTYPE_MC)
                && <McOptions question={question} hasCorrectAnswer={hasCorrectAnswer} />
            }



        </>
    );

    if (singleQuestion) {
        return questionComponent;
    }
    return (
        <Container fluid>

                {questionComponent}
        </Container>
    );
};
 Question =  connect(
    (state, props) => ({
        qType: selector(state, `${props.question}.qType`),
        dType: selector(state, `${props.question}.dType`),
        hasCorrectAnswer: selector(state, `${props.question}.hasCorrectAnswer`),
        allowMultipleAnswers: selector(state, `${props.question}.allowMultipleAnswers`),

    })
)(Question);

export default Question;
