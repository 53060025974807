import { TOGGLE_SIDEBAR, TOGGLE_SIDEBAR_MENU } from '../actions/types';

export default (state = { sidebarHidden: false, menu: {} }, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarHidden: !state.sidebarHidden };
    case TOGGLE_SIDEBAR_MENU: {
      const item = action.payload;
      if (item) {
        return { ...state, menu: { ...state.menu, [item]: !state.menu[item] } };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
};
