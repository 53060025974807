import React from 'react';

import {change, Field, FieldArray, formValueSelector} from 'redux-form';
//import {connect, useSelector} from "react-redux";
//import renderInput from "./renderers/renderInput";
import '../../i18n';

import Button from "react-bootstrap/Button";
//import Row   from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//import renderSelect from "./renderers/renderSelect";

import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import renderError from "./renderers/renderError";
import Condition from './Condition';
import {bconfirm} from "../utils/ConfirmAlert";


let lastId = Math.floor(Math.random()*10000);
const generatedId = () => {lastId = lastId + 1; return lastId};

const RenderConditions = ({ dispatch, fields, meta, level, block, operator, scene, game, formName }) => {
    return (
        <>
            <Form.Row className="align-items-center">
                <Col xs="auto">
                    <Button className="mb-2" onClick={() => fields.push({generatedId:generatedId()})}><i className="fas fa-plus"></i> Add Condition</Button>
                </Col>
            </Form.Row>
            {renderError(meta, true)}
            {fields.map(
                (condition, index) => {
                    const conditionObject=fields.get(index);
                    const key=conditionObject._id?conditionObject._id:'condition'+conditionObject.generatedId;
//console.log('==============', key);
                    return (
                        <div key={key} className={'condition_container border p-1 mb-1 rounded'}>
                                {
                                    //operatorText
                                }

                                <Condition key={key} condition={condition} fields={fields} index={index} operator={operator} scene={scene} game={game} formName={formName} dispatch={dispatch} />
                        </div>
                    )
                }
            )}


        </>
    )
};

const ConditionBlock = ({dispatch, index, block, level, fields, scene, game, formName, meta}) => {
    //console.log('Condition block meta', meta);
  //  console.log("fkjsdrkefsd2  GOT FIELDS,index,level", fields,index,level);

    const conditionBlockObject=fields?fields.get(index):{};
    return (
        <>
            <div className="border p-1 mb-1 rounded">
                <div  className="d-flex justify-content-between">
                    <div>
                        {
                            // <Field name={`${block}.andOrConditions`} noFormGroup={true} asCol={false}
                            //        component={renderSelect} hideLabel={true}
                            //        label="Boolean operator for condition elements of this group">
                            //     {['AND', 'OR'].map(option => <option key={`${option}`}
                            //                                          value={option}>Combine all conditions of this group
                            //         with {option}</option>)}
                            // </Field>
                        }
                        <Field name={`${block}.andOrConditions`} component="input" type="hidden" value={"AND"} /> AND
                    </div>
                    <div>
                        <Button className={"mr-1"} variant="outline-danger"
                                onClick={async () => {
                                    const result = await bconfirm('Are you really sure?', {cancelButtonStyle: 'secondary'});
                                    if (result) {
                                        fields.remove(index)
                                    }
                                }} title="Remove Block"
                        ><i className="fa fa-times"></i><span className={"controlLabel"}> Remove Block</span></Button>
                    </div>
                </div>

                <div className={'ml-4'}>
                    <FieldArray name={`${block}.conditions`} component={RenderConditions}
                                props={{block:block, level:level, operator:conditionBlockObject.andOrConditions, scene:scene, game:game, formName:formName, dispatch:dispatch, }} />
                </div>

            </div>
            {
                (index < fields.length - 1) &&
                <div className={'mt-2 mb-2 font-weight-bold'}>
                    {
                    // <Field name={`${block}.andOrNextBlock`} noFormGroup={true} asCol={false}
                    //        component={renderSelect} additionalControlClass='font-weight-bold'additionalControlStyle={{width:'initial',marginLeft:'auto',marginRight:'auto'}} hideLabel={true} label="Boolean operator for next group">
                    //     {['AND', 'OR'].map(option => <option key={`${option}`}
                    //                                          value={option}>{option} NOT USED ALWAYS OR</option>)}
                    // </Field>
                    }
                    <Field name={`${block}.andOrNextBlock`} component="input" type="hidden" value={"OR"} /> OR
                </div>
            }
        </>
    );

}

const RenderConditionBlocks = ({ fields, meta, level, scene, game, formName }) => {
//console.log('RenderConditionBlocks meta', meta);
    return (
        <>
            <Form.Row className="align-items-center">
                <Col lg="12">
                    <Button className="mb-2" onClick={() => fields.push({
                        generatedId:generatedId(),
                        andOrConditions:'AND',
                        andOrNextBlock:'OR',
                        conditions:[{generatedId:generatedId()}]
                    })}><i className="fas fa-plus"></i> Add new Condition Block</Button>
                </Col>
            </Form.Row>
            {renderError(meta, true)}

            {fields.map(
                (block, index) => {
                    const conditionBlockObject=fields.get(index);
                    // as key, I either use the mongodb id, or the generated id for new objects
                    const key=conditionBlockObject._id?conditionBlockObject._id:'conditionblock'+conditionBlockObject.generatedId;
                    return (
                        <div key={key}>
                            <ConditionBlock  index={index} block={block} level={level} fields={fields} scene={scene} game={game} formName={formName} />
                        </div>
                    )
                }
            )}


        </>
    )
};
const ConditionForm2 = ({dispatch, baseFieldName, fieldNameParentCondition, level, scene, game, formName}) => {
    const { t, i18n } = useTranslation();

    if (!level) {
        level = 0;
    }
    let fieldName = baseFieldName;
    if (fieldNameParentCondition) {
        fieldName = `${fieldNameParentCondition}.${baseFieldName}`;
    }
    return (
        <div style={{paddingLeft:level*10+'px'}}>
                <div>
                    {
                        // ta to props prop, pernietai ws props sto component RenderConditions
                    }
                    <FieldArray name={fieldName} component={RenderConditionBlocks}
                                props={{fieldNameParentCondition:fieldName, level:level, scene:scene, game:game, formName:formName}} />
                </div>
        </div>
    );

}

export default ConditionForm2;


